export default async function ({ $auth, store, $cookies, $sentry }) {
  console.log('auth plugins start');

  if ($auth.user) {
    $sentry.setUser({
      id: $auth.user.id.toString(),
      username: $auth.user.username,
    });
  }

  $auth.onRedirect((to): string => {
    if (!$auth.loggedIn) {
      store.commit('organization/clearOrganizations');
    }
    return to;
  });

  const isFetch = store.state.cash.authPlugins;
  if (!isFetch) {
    store.commit('cash/setAuthPlugins');
    await store.dispatch('enums/getProfileEnums');
    if ($auth.loggedIn) {
      await store.dispatch('organization/getOrganizations');

      if ($cookies.get('organizationActive')) {
        await store.dispatch(
          'organization/setActiveOrganization',
          $cookies.get('organizationActive')
        );
      } else {
        await store.dispatch('userSubscribe/getSubscribe');
      }
      await store.dispatch('userFavorites/getFavorites');
    }
    // console.log('auth plugins use');
  }
}
