import { CommunicationType } from '~/declarations/shared';
import { EventStatus } from '~/constants/event-status';
import { BlockContent } from '~/declarations/content';

export const ROUTER_LINK = {
  main: '/',
  events: '/events/',
  favorites: '/favorites/',
  manageEvents: '/manage-events/',
  forVolunteers: '/about/#volunteers',
  forOrganization: '/about/#organizations',
  forCooperation: '/about/#cooperation',
  about: '/about/',
  bonus: '/bonus/',
  contacts: '/contacts/',
  addEvents: {
    main: '/personal/add-event/',
    volunteers: '/personal/add-event/volunteers/',
  },
  agreement: '/doc/Пользовательское соглашение.docx',
  privacy: '/doc/Политика ПДн Фонда.docx',
  consentToProcessing: '/doc/Согласие пользователя.docx',
  personal: '/personal/',
  personalProfile: {
    profile: '/personal/profile/',
    news: '/personal/news/',
    newsCreate: '/personal/news/create/'
  },
  createOrganization: '/personal/create-organization/',
  myEvents: {
    participant: '/personal/events/',
    creator: '/personal/events/creator/',
  },
  myChat: '/personal/chat/',
  myFavorites: {
    events: '/personal/favorites/',
    company: '/personal/favorites/company/',
    volunteers: '/personal/favorites/volunteers/',
  },
  myNotifications: {
    main: '/personal/notify/',
    event: '/personal/notify/event/',
  },
  mySettings: {
    profile: '/personal/settings/',
    profileVolunteer: '/personal/settings/profile/?activeTab=volunteer',
    paramsAuth: '/personal/settings/auth/',
    privacy: '/personal/settings/privacy/',
    subscribes: '/personal/settings/subscribe/',
    reviews: '/personal/settings/reviews/',
    createOrganization: '/personal/create-organization/',
  },
  myDiaryOfVolunteer: '/personal/journal/',
  myBonus: '/bonus/#myBonus',
  myAwards: '/personal/awards/',
  invites: {
    main: '/invites/',
    eventsList: '/invites/events-list/',
  },
  moderation: '/moderation/',
  edit: '/edit/',
  finish: '/finish/',
  view: '/view/',
  volunteers: {
    all: '/volunteers/all/',
    new: '/volunteers/',
    accepted: '/volunteers/accepted/',
    declined: '/volunteers/declined/',
  },
  organization: '/organization/',
  organizationProfile: {
    profile: '/organization/profile/',
    news: '/organization/news/',
    newsCreate: '/organization/news/create/'
  },
  organizationManagers: {
    list: '/organization/managers/',
  },
  organizationEvents: {
    created: '/organization/events/',
  },
  organizationChat: '/organization/chat/',
  organizationNotify: '/organization/notify/',
  organizationFeedback: '/organization/feedback/',
  organizationAddEvents: {
    main: '/organization/add-event/',
    volunteers: '/organization/add-event/volunteers/',
  },
  organizationSettings: {
    card: '/organization/settings/',
    profile: '/organization/settings/profile/',
    privacy: '/organization/settings/privacy/',
  },
  organizationInvites: {
    main: '/invites/',
    eventsList: '/invites/events-list/',
  },
  organizationFavorites: {
    events: '/organization/favorites/',
    agency: '/organization/favorites/agency/',
    volunteers: '/organization/favorites/volunteers/',
  },
  news :{
    main: '/news/',
    detail: '/news/#idNews'
  }
};

export const NAV_LIST = (menu: BlockContent['menuItems']) => [
  {
    id: 1,
    url: ROUTER_LINK.events,
    title: menu.menu_item1,
  },
  {
    id: 7,
    url: ROUTER_LINK.news.main,
    title: 'Новости',
  },

  {
    id: 2,
    url: ROUTER_LINK.forVolunteers,
    title: menu.menu_item2,
  },

  {
    id: 3,
    url: ROUTER_LINK.forOrganization,
    title: menu.menu_item3,
  },

  {
    id: 4,
    url: ROUTER_LINK.about,
    title: menu.menu_item4,
  },

  // {
  //   id: 5,
  //   url: ROUTER_LINK.bonus,
  //   title: 'Бонусы и парнтеры',
  // },

  {
    id: 6,
    url: ROUTER_LINK.contacts,
    title: menu.menu_item5,
  },
];

export const AUTH_COMPONENTS = {
  login: 'ModalLogin',
  reset: 'ModalPassReset',
  code: 'ModalCodeLogin',
  newPass: 'ModalNewPass',
  success: 'ModalSuccess',
  registration: 'ModalRegister',
  codeRegistration: 'ModalCodeRegister',
  managerEditRole: 'ModalManagerEditRole',
  managerEdtiSuccess: 'ModalManagerEditSuccess',
  managerEditError: 'ModalManagerEditError',
  managerAdd: 'ModalManagerAdd',
  managerSuccess: 'ModalManagerSuccess',
  managerError: 'ModalManagerError',
  phoneConfirm: 'ModalPhoneConfirm',
  phoneConfirmCode: 'ModalPhoneConfirmCode',
};

export const KEY_COMMUNICATIONS: Array<CommunicationType> = [
  'phone',
  'email',
  'facebook',
  'vk',
  'instagram',
  'whatsapp',
  'viber',
  'telegramm',
  'website',
];

export const STATUS_EVENT = {
  [EventStatus.draft]: {
    value: 'draft',
    text: 'Черновик',
  },
  [EventStatus.new]: {
    value: 'new',
    text: 'Новое',
  },
  [EventStatus.on_inspection_created]: {
    value: 'on_inspection_created',
    text: 'На проверке (Создание)',
  },
  [EventStatus.on_correction_created]: {
    value: 'on_correction_created',
    text: 'На корректировке (Создание)',
  },
  [EventStatus.active]: {
    value: 'active',
    text: 'Активно',
  },
  [EventStatus.deleted]: {
    value: 'deleted',
    text: 'Удалено',
  },
  [EventStatus.on_inspection_completed]: {
    value: 'on_inspection_completed',
    text: 'На проверке (Завершение)',
  },
  [EventStatus.on_correction_completed]: {
    value: 'on_correction_completed',
    text: 'На корректировке (Завершение)',
  },
  [EventStatus.completed]: {
    value: 'completed',
    text: 'Завершено',
  },
  [EventStatus.cancel]: {
    value: 'cancel',
    text: 'Отменено',
  },
};

export const FAVORITE_TYPES = {
  events: 'event',
  users: 'user',
  organizations: 'organization',
};
//  moderator : 'Модератор'
export const ROLE_ORGANIZATION = {
  administrator: {
    key: 'administrator',
    value: 'Администратор',
  },
  manager: {
    key: 'manager',
    value: 'Менеджер',
  },
  member: {
    key: 'member',
    value: 'Участник',
  },
  owner: {
    key: 'owner',
    value: 'Владелец',
  },
};

export const OptionsCookies = {
  path: '/',
  maxAge: 60 * 60 * 24 * 7,
};

export const LABEL_KEY = {
  phone_number: 'Номер телефона',
  email: 'Почта',
  age: 'Дата рождения',
  education_level: 'Уровень образования',
  blood_type: 'Группа крови',
  sex: 'Пол',
  volunteering_type: 'Тип волонтерства',
  employment_type: 'Тип занятости',
  category: 'Тип деятельности',
  health_restrictions: 'Ограничения по здоровью',
  frequency_of_sports: 'Частота занятий спортом',
  car_presence: 'Наличие автомобиля',
  sports_training: 'Частота занятий спортом',
  nationality: 'Гражданство',
  skill_type: 'Тип Навыков',
  about: 'О себе',
};
