<template>
  <div class="modal__body">
    <div class="modal__top">
      <div class="modal__top-left">
        <button
          v-if="isBackbutton"
          class="btn btn-modal"
          @click="handlerGoBack"
        >
          <svg-icon
            name="arrow"
            width="9px"
            height="18.5px"
            stroke="#838383"
          ></svg-icon>
        </button>
        <p class="modal__title">{{ title }}</p>
      </div>
      <button v-if="closable" class="btn btn-close" @click="handlerCloseModal">
        <svg-icon
          name="close"
          width="18.5px"
          height="18.5px"
          stroke="#838383"
        ></svg-icon>
      </button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    isBackbutton: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    handlerGoBack() {
      this.$emit('goBack');
    },
    handlerCloseModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style>
</style>
