export enum EventStatus {
  // Черновик
  draft = 'draft',
  // Новое'
  new = 'new',
  // На проверке (Создание)'
  on_inspection_created = 'on_inspection_created',
  // На корректировке (Создание)'
  on_correction_created = 'on_correction_created',
  // Активно'
  active = 'active',
  // Удалено'
  deleted = 'deleted',
  // На проверке (Завершение)'
  on_inspection_completed = 'on_inspection_completed',
  // На корректировке (Завершение)'
  on_correction_completed = 'on_correction_completed',
  // Завершено'
  completed = 'completed',
  // Отменено
  cancel = 'cancel',
}
