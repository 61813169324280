var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside-custom",rawName:"v-click-outside-custom",value:(_vm.closeMenuProfile),expression:"closeMenuProfile"}],staticClass:"actions__user user-header",on:{"mouseenter":function($event){$event.preventDefault();return _vm.openMenuProfile.apply(null, arguments)},"mouseleave":function($event){$event.preventDefault();return _vm.closeMenuProfile.apply(null, arguments)}}},[_c('div',{staticClass:"user-header__wrapper-user",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openMenuProfile.apply(null, arguments)}}},[(_vm.isUserActive)?_c('CardUser',_vm._b({attrs:{"class-mod":"_hover user-header__profile","only-icon":true,"svg-icon":{
        name: 'user',
        stroke: '#202020',
      }}},'CardUser',_vm.userProfile,false)):_c('CardUser',_vm._b({attrs:{"class-mod":"_hover user-header__profile","only-icon":true}},'CardUser',_vm.orgActive,false))],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.openMenu),expression:"openMenu"}],staticClass:"user-header__menu"},[_c('div',{staticClass:"user-header__wrapper"},[_c('ul',{staticClass:"user-header__list"},[_c('li',[_c('NuxtLink',{staticClass:"user-header__link",attrs:{"to":_vm.personalLink},nativeOn:{"click":function($event){return _vm.setUserActive.apply(null, arguments)}}},[_c('CardUser',_vm._b({attrs:{"svg-icon":{
                  name: 'user',
                  stroke: '#202020',
                },"class-mod":{ _hover: true, _active: !_vm.isUserActive }}},'CardUser',_vm.userProfile,false))],1)],1),_vm._v(" "),_vm._l((_vm.organizationAvatars),function(org){return _c('li',{key:org.id},[(org.is_accepted)?_c('NuxtLink',{staticClass:"user-header__link",attrs:{"to":("" + _vm.organizationLink)},nativeOn:{"click":function($event){return _vm.setActiveOrganization(org.id)}}},[_c('CardUser',_vm._b({attrs:{"class-mod":{
                  _hover: true,
                  _active: _vm.orgActive ? _vm.orgActive.id !== org.id : true,
                }}},'CardUser',org,false))],1):_c('CardUser',_vm._b({staticClass:"user-header__link _active",attrs:{"title":"Организация находиться на модерации"}},'CardUser',org,false))],1)})],2),_vm._v(" "),_c('button',{staticClass:"user-header__exit",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.handlerLogout.apply(null, arguments)}}},[_c('span',{staticClass:"user-header__exit-icon"},[_c('svg-icon',{attrs:{"name":"log-out"}})],1),_vm._v(" "),_c('span',[_vm._v("Выйти")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }