













































































































































































































































import { FAVORITE_TYPES, ROLE_ORGANIZATION, ROUTER_LINK } from '@/constants/constList';
import { formatForCardEvent, initDate, isDateMoreNow } from '@/constants/constFormated';
import CircleRating from '@/components/ui/CircleRating.vue';
import FavoriteIcon from '@/components/ui/FavoriteIcon.vue';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { Event } from '~/declarations/events';
import { WithManageButton } from '~/declarations/shared';
import { EventStatus } from '~/constants/event-status';

@Component({
  name: 'Card',
  components: {
    CircleRating,
    FavoriteIcon,
  },
})
export default class Card extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  event!: WithManageButton<Event>;

  @Prop({ type: String, default: 'div' })
  tagName!: string;

  @Prop({ type: Boolean, default: false })
  favoritePage!: boolean;

  @Prop({ type: Boolean, default: false })
  isReview!: boolean;

  eventsUrl = ROUTER_LINK.events;

  isShowImage = false;

  typeEvent = FAVORITE_TYPES.events;

  isFavorite = true;

  get eventTypes() {
    return this.$typedStore.getters['enums/getEventsCategoryObject'];
  }

  get isDraft(): boolean {
    return this.event.status === EventStatus.draft;
  }

  get rate() {
    return this.event.complexity || null;
  }

  get eventCard() {
    return formatForCardEvent(this.event);
  }

  get formatStartDate() {
    return initDate(this.eventCard.startDate);
  }

  get cardDetailUrl() {
    if (this.event) {
      return `${ROUTER_LINK.events}${this.event.id}/`;
    }
    return '';
  }

  get isRate() {
    return this.eventCard.feedback ? this.eventCard.feedback.rate : false;
  }

  get pageManage() {
    let url;
    if (this.$route.path.includes('personal')) {
      url = `/personal/manage-event/${this.eventCard.id}/`;
    } else if (this.$route.path.includes('organization')) {
      url = `/organization/manage-event/${this.eventCard.id}/`;
    }
    return url;
  }

  get countStars() {
    if (!this.eventCard.feedback || !this.eventCard.feedback.rate) return [];
    const { feedback } = this.eventCard;
    const lengthArray = Math.ceil(this.eventCard.feedback.rate); // 3
    return Array.from({ length: lengthArray }, (el, indx) => {
      const res = feedback.rate - (indx + 1);
      if (res > 0) {
        return 1;
      } else {
        return feedback.rate - indx;
      }
    });
  }

  get iconEventType() {
    const mainTypeId = this.event.main_type || this.event.event_type[0];
    if (this.eventTypes[mainTypeId]) {
      return this.eventTypes[mainTypeId].icon;
    }
    return '/img/logo-small.svg';
  }

  get isCanEdit() {
    if (this.$auth.loggedIn) {
      if (!this.event.buttons?.btnManage) {
        const user = this.$auth.user;
        const activeOrg = this.$typedStore.state.organization.activeOrganization;
        const isCurrentOrg = this.event.organisation === activeOrg;
        if (activeOrg) {
          if (this.event.owner_type === 'organization' &&
            isCurrentOrg && (
              this.event.coordinator === String(user.id) ||
              ['administrator', 'manager', 'owner'].includes(String(this.$typedStore.state.organization.activeOrganizationRole))
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else if (
          this.event.owner_type === 'user' &&
          this.event.owner === user.id
        ) {
          return true;
        }
      }
    }
    return false;
  }

  handlerCloseSearch() {
    this.$emit('closeSearch');
  }

  activateReadMore() {
    if (this.eventCard.feedback) {
      this.eventCard.feedback.readMoreActivated = true;
    }
  }

  hideReadMore() {
    if (this.eventCard.feedback) {
      this.eventCard.feedback.readMoreActivated = false;
    }
  }

  setFavorite(val) {
    this.isFavorite = val;
  }

  // Fix personal data
  get сardAuthorUrl() {
    if (!this.$auth.loggedIn) return null;
    if (this.eventCard.author.typeUrl === FAVORITE_TYPES.organizations) {
      return `/000${this.eventCard.author.id}/`;
    }
    return `/${this.eventCard.author.id}/`;
  }

  get cardEditUrl() {
    if (this.event.owner_type === 'user') {
      return `/personal/manage-event/${this.eventCard.id}/`;
    } else {
      return `/organization/manage-event/${this.eventCard.id}/`;
    }
  }


  async mounted() {
    try {
      await this.$axios.get(this.eventCard.image);
      this.isShowImage = true;
    } catch (e) {
      this.isShowImage = false;
    }
  }
};
