<template>
  <div
    class="icon-bookmark"
    :class="{ add: isFavorite }"
    @click.stop.prevent="handlerToggleFavorite"
  >
    <svg-icon
      ref="icon"
      name="bookmark"
      stroke="#b8d852"
      :fill="isFavorite ? '#b8d852' : 'none'"
      width="14"
      height="19"
    ></svg-icon>
  </div>
</template>

<script>
export default {
  props: {
    typeFavorite: {
      type: String,
      default: '',
    },
    element: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isFavorite: false,
      timer: 300,
      timerFunc: null,
    };
  },
  computed: {
    favoriteEvents() {
      return this.$typedStore.state.userFavorites.favoriteEvent;
    },
  },
  watch: {
    favoriteEvents() {
      this.isFavorite = this.$typedStore.getters['userFavorites/isFavorite'](
        this.typeFavorite,
        this.element.id
      );
    },
    element() {
      this.isFavorite = this.$typedStore.getters['userFavorites/isFavorite'](
        this.typeFavorite,
        this.element.id
      );
    },
  },
  mounted() {
    this.isFavorite = this.$typedStore.getters['userFavorites/isFavorite'](
      this.typeFavorite,
      this.element.id
    );
  },
  methods: {
    handlerToggleFavorite() {
      clearTimeout(this.timerFunc);
      this.timerFunc = setTimeout(async () => {
        if (this.$auth.loggedIn) {
          if (!this.isFavorite) {
            this.isFavorite = true;
            const result = await this.$typedStore.dispatch(
              'userFavorites/addFavorites',
              {
                type: this.typeFavorite,
                element: this.element,
              }
            );
            if (!result) {
              this.$toast.error('не удалось добавить в избранное!');
              this.isFavorite = false;
            }
          } else {
            this.isFavorite = false;
            const result = this.$typedStore.dispatch(
              'userFavorites/removeFavorite',
              {
                type: this.typeFavorite,
                element: this.element,
              }
            );

            if (!result) {
              this.$toast.error('не удалось удалить из избранного!');
              this.isFavorite = true;
            }
          }
        } else if (!this.$auth.loggedIn) {
          this.$toast.show('Авторизуйтесь для добавление в избранное!');
        }
      }, this.timer);
    },
  },
};
</script>

<style>
</style>
