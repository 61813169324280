var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBody',{attrs:{"title":_vm.title},on:{"closeModal":_vm.closeModal}},[_c('div',{staticClass:"modal__content"},[_c('ValidationObserver',{attrs:{"name":"newPass","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-modal",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitNewPass)}}},[_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"password","rules":{
            required: true,
            password: true,
          },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"password"}},[_vm._v("\n            Пароль "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('div',{staticClass:"input-with-icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass),expression:"pass"}],staticClass:"input",class:{ 'input--error': validated && errors.length > 0 },attrs:{"id":"password","type":"password","autocomplete":"off"},domProps:{"value":(_vm.pass)},on:{"focus":_vm.resetShowPassword,"input":function($event){if($event.target.composing){ return; }_vm.pass=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"icon-eye",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.toggleShowPassword.apply(null, arguments)}}},[_c('svg-icon',{attrs:{"name":"eye","width":"22","height":"16"}})],1)]),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"message"},[_vm._v("Пароль должен содержать и буквы, и цифры, не менее 8\n            символов")])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"secPassword","rules":{
            required: true,
            setPassword: ['@password'],
          },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"sec-password"}},[_vm._v("\n            Подтверждение пароля "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('div',{staticClass:"input-with-icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secPass),expression:"secPass"}],staticClass:"input",class:{ 'input--error': validated && errors.length > 0 },attrs:{"id":"sec-password","type":"password","autocomplete":"off"},domProps:{"value":(_vm.secPass)},on:{"focus":_vm.resetShowPassword,"input":function($event){if($event.target.composing){ return; }_vm.secPass=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"icon-eye",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.toggleShowPassword.apply(null, arguments)}}},[_c('svg-icon',{attrs:{"name":"eye","width":"22","height":"16"}})],1)]),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn btn-green btn--arrow form__button",attrs:{"type":"submit"}},[_c('span',[_vm._v("Продолжить")]),_vm._v(" "),_c('svg-icon',{attrs:{"name":"arrow","width":"6px","height":"10px","stroke":"#ffffff"}})],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }