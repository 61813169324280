/*
TODO List
[ ] Вход через соц. сети
? [ ] Возможно разбить компанент на более мелкие
? [ ] Вынести апи url в константы
[ ] Ссылки на политику и соглашение
*/

<template>
  <ModalBody :title="title" @closeModal="closeModal">
    <div class="modal__content">
      <div v-if="errorsRequest.length" class="errors">
        <p v-for="error in errorsRequest" :key="error">{{ error }}</p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }" name="registration" slim>
        <form
          class="form-modal"
          @submit.prevent="handleSubmit(submitRegistration)"
        >
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="phone"
            :rules="{ required: true, phone: true }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="phone-reg">
              Номер телефона <span class="input-req"> *</span>
            </label>
            <input
              id="phone-reg"
              v-model="phone"
              v-mask="phoneMask"
              type="text"
              class="input"
              :class="{
                'input--error': validated && errors.length > 0,
              }"
              autocomplete="off"
              placeholder="+7 (___) ___-__-__"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="name"
            :rules="{ required: true }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="name-reg">
              Имя <span class="input-req"> *</span>
            </label>
            <input
              id="name-reg"
              v-model="name"
              type="text"
              class="input"
              :class="{
                'input--error': validated && errors.length > 0,
              }"
              autocomplete="off"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="lastName"
            :rules="{ required: true }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="lastName-reg">
              Фамилия <span class="input-req"> *</span>
            </label>
            <input
              id="lastName-reg"
              v-model="lastName"
              type="text"
              class="input"
              :class="{
                'input--error': validated && errors.length > 0,
              }"
              autocomplete="off"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="middleName"
            :rules="{}"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="middleName-reg">
              Отчество
            </label>
            <input
              id="middleName-reg"
              v-model="middleName"
              type="text"
              class="input"
              :class="{
                'input--error': validated && errors.length > 0,
              }"
              autocomplete="off"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="phone"
            :rules="{ required: true, email: true }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="email-reg">
              Почта <span class="input-req"> *</span>
            </label>
            <input
              id="email-reg"
              v-model="email"
              type="text"
              class="input"
              :class="{
                'input--error': validated && errors.length > 0,
              }"
              autocomplete="off"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="password"
            :rules="{
              required: true,
              password: true,
            }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="password">
              Пароль <span class="input-req"> *</span>
            </label>
            <div class="input-with-icon">
              <input
                id="password"
                v-model="pass"
                type="password"
                class="input"
                :class="{ 'input--error': validated && errors.length > 0 }"
                autocomplete="off"
                @focus="resetShowPassword"
              />
              <button
                class="icon-eye"
                type="button"
                tabindex="-1"
                @click.prevent="toggleShowPassword"
              >
                <svg-icon name="eye" width="22" height="16"></svg-icon>
              </button>
            </div>
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
            <span class="message"
            >Пароль должен содержать и буквы, и цифры, не менее 8
              символов</span
            >
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="secPassword"
            :rules="{
              required: true,
              setPassword: ['@password'],
            }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="sec-password">
              Подтверждение пароля <span class="input-req"> *</span>
            </label>
            <div class="input-with-icon">
              <input
                id="sec-password"
                v-model="secPass"
                type="password"
                class="input"
                :class="{ 'input--error': validated && errors.length > 0 }"
                autocomplete="off"
                @focus="resetShowPassword"
              />
              <button
                class="icon-eye"
                type="button"
                @click.prevent="toggleShowPassword"
              >
                <svg-icon name="eye" width="22" height="16"></svg-icon>
              </button>
            </div>
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="agreement"
            rules="checkboxReq"
            tag="div"
            class="form-modal__confirm"
          >
            <input id="remember" v-model="agreement" type="checkbox"/>

            <label for="remember" class="label">
              Я прочитал и согласен с условиями
              <a
                :href="ROUTER_LINK.agreement"
                target="_blank"
              >Пользовательского соглашения
              </a>
              , <a
              :href="ROUTER_LINK.privacy"
              target="_blank"
            >Политикой конфиденциальности</a>
              , и даю
              <a
                :href="ROUTER_LINK.consentToProcessing"
                target="_blank"
              >согласие</a> на обработку персональных данных.
            </label>

            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <!-- // buttons  -->
          <button
            class="btn btn-green btn--arrow form-modal__button"
            type="submit"
          >
            <span>Регистрация</span>
            <svg-icon
              name="arrow"
              width="6px"
              height="10px"
              stroke="#ffffff"
            ></svg-icon>
          </button>
        </form>
      </ValidationObserver>
      <!-- <div class="modal__services">
        <p class="modal__services-label">Регистрация через сервисы:</p>
        <SocialsAuth class-name="modal__social" :socials="socials" />
      </div> -->
      <div class="modal__bottom">
        <p>Есть аккаунт?</p>
        <button class="create-link" @click="handlerOpenModalLogin">
          <span>Войти</span>
        </button>
      </div>
    </div>
  </ModalBody>
</template>

<script>
// import SocialsAuth from '@/components/Socials/SocialsAuth.vue';
import ModalBody from '@/components/Modals/ModalBody/ModalBody.vue';
import { AUTH_COMPONENTS, ROUTER_LINK } from '@/constants/constList';
import { phoneMask, unmask } from '@/constants/constFormated';
import {
  toggleShowPassword,
  resetShowPassword,
} from '@/constants/constFunctions';
import { flatten } from 'lodash';

export default {
  name: 'ModalRegister',
  components: {
    ModalBody,
    // SocialsAuth,
  },
  data() {
    return {
      title: 'Регистрация',
      ROUTER_LINK,
      socials: [
        {
          name: 'social-ya',
          nameHv: 'yandex-hv',
          url: '',
          width: '11',
          height: '16',
        },
        {
          name: 'social-gm',
          nameHv: 'gmail-hv',
          url: '',
          width: '16',
          height: '13',
        },
        {
          name: 'social-mail',
          nameHv: 'mail-hv',
          url: '',
          width: '15',
          height: '15',
        },
        {
          name: 'social-vk',
          nameHv: 'vk-hv',
          url: '',
          width: '19',
          height: '11',
        },
        {
          name: 'social-google',
          nameHv: 'google-hv',
          url: '',
          width: '16',
          height: '16',
        },
        {
          name: 'social-fb',
          nameHv: 'fb-hv',
          url: '',
          width: '8.5',
          height: '17.5',
        },
      ],
      errorsRequest: [],
      phone: '',
      pass: '',
      secPass: '',
      agreement: false,
      phoneMask,
      name: '',
      email: '',
      lastName: '',
      middleName: '',
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    handlerOpenModalLogin() {
      this.$emit('openModalNext', { modal: AUTH_COMPONENTS.login });
    },
    toggleShowPassword,
    resetShowPassword,
    async submitRegistration() {
      const phoneUnmask = unmask(this.phone, phoneMask);
      const data = {
        phone: phoneUnmask,
        firstName: this.name,
        lastName: this.lastName,
        middleName: this.middleName,
        email: this.email,
        password: this.pass,
        password2: this.secPass,
      };

      const dataRequest = { phone: phoneUnmask };
      try {
        const { data: phoneValid } = await this.$axios.post(
          '/api/valid-phone/',
          dataRequest,
        );

        if (phoneValid.isExists)
          throw new Error('Пользователь уже существует!');
        if (!phoneValid.result) throw new Error('Некорректный номер телефона');

        await this.$api.auth.sendCode({ ...dataRequest, email: data.email });

        this.$emit('openModalNext', {
          modal: AUTH_COMPONENTS.codeRegistration,
          data,
        });
      } catch (e) {
        if (e.response?.data) {
          this.errorsRequest = flatten(Object.values(e.response.data));
        } else if (e instanceof Error) {
          this.errorsRequest = [e.message];
        }
      }
    },
  },
};
</script>
