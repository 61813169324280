






























// styles
// import 'assets/scss/_common.scss';

import { getAvatarUser, getProfileHeader } from '@/constants/constFormated';
import { Component, mixins } from 'nuxt-property-decorator';
import Lk from '~/mixins/lk';
import HasHeaderData from '~/mixins/has-header-data';
import Header from '~/components/Header/Header.vue';
import Footer from '~/components/Footer/Footer.vue';

@Component({
  components: {
    Header,
    Footer,
    BreadCrumbs: () => import('@/components/ui/BreadCrumbs.vue'),
    PersonalHeader: () => import('@/components/Personal/PersonalHeader.vue'),
    PersonalStartInfo: () =>
      import('@/components/Personal/PersonalStartInfo.vue'),
  },
})
export default class PersonalProfile extends mixins(Lk,HasHeaderData) {
  async fetch() {
    await this.$typedStore.dispatch('enums/getProfileEnums');
    getProfileHeader(this.$typedStore);
  }

  get fullStatistic() {
    return this.$route.fullPath.includes('/profile/');
  }

  get profileUser() {
    const user = this.$auth.user;
    return getAvatarUser(user);
  }

  get profileStatistic() {
    if (this.fullStatistic) {
      return (
        [
          ...this.$typedStore.state.profileInfoStatistic.statisticMain,
          ...this.$typedStore.state.profileInfoStatistic.statisticOther,
        ] || []
      );
    }
    return this.$typedStore.state.profileInfoStatistic.statisticMain || [];
  }

  get profileAwards() {
    if (this.fullStatistic) {
      return this.$typedStore.state.profileInfoStatistic.awards || [];
    }
    return [];
  }

  get profileEventCount() {
    return Number(this.$auth.user?.event_member_count || 0);
  }

  get profilePointCount() {
    return Number(this.$auth.user?.sum_rating || 0);
  }

  mounted() {
    this.$fetch();
    const hash = this.$route.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) this.$smoothScroll({ scrollTo: element });
    }
  }
};
