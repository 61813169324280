import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _728a9c30 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _06d3b3bd = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _726aa35a = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _02e57448 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _1f999cce = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2a1eadd4 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _19752b76 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _91588f02 = () => interopDefault(import('../pages/organization/index/news/index.vue' /* webpackChunkName: "pages/organization/index/news/index" */))
const _5ad7a21f = () => interopDefault(import('../pages/organization/index/news/create.vue' /* webpackChunkName: "pages/organization/index/news/create" */))
const _748e7bea = () => interopDefault(import('../pages/organization/index/news/_id/index.vue' /* webpackChunkName: "pages/organization/index/news/_id/index" */))
const _e0d67d0e = () => interopDefault(import('../pages/organization/index/index.vue' /* webpackChunkName: "pages/organization/index/index" */))
const _96f4277a = () => interopDefault(import('../pages/personal/index.vue' /* webpackChunkName: "pages/personal/index" */))
const _10e734dc = () => interopDefault(import('../pages/personal/index/news/index.vue' /* webpackChunkName: "pages/personal/index/news/index" */))
const _21b4976c = () => interopDefault(import('../pages/personal/index/news/create.vue' /* webpackChunkName: "pages/personal/index/news/create" */))
const _5fb4677d = () => interopDefault(import('../pages/personal/index/news/_id/index.vue' /* webpackChunkName: "pages/personal/index/news/_id/index" */))
const _b11a16f4 = () => interopDefault(import('../pages/personal/index/index.vue' /* webpackChunkName: "pages/personal/index/index" */))
const _323bb112 = () => interopDefault(import('../pages/organization/add-event.vue' /* webpackChunkName: "pages/organization/add-event" */))
const _26ff77a5 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _3c2641fe = () => interopDefault(import('../pages/organization/events/created.vue' /* webpackChunkName: "pages/organization/events/created" */))
const _57a0584c = () => interopDefault(import('../pages/organization/events/drafts.vue' /* webpackChunkName: "pages/organization/events/drafts" */))
const _450a2330 = () => interopDefault(import('../pages/organization/events/index.vue' /* webpackChunkName: "pages/organization/events/index" */))
const _fc50870a = () => interopDefault(import('../pages/organization/favorites.vue' /* webpackChunkName: "pages/organization/favorites" */))
const _67fe5d46 = () => interopDefault(import('../pages/organization/favorites/events.vue' /* webpackChunkName: "pages/organization/favorites/events" */))
const _8e1f2f26 = () => interopDefault(import('../pages/organization/favorites/volunteers.vue' /* webpackChunkName: "pages/organization/favorites/volunteers" */))
const _1685babe = () => interopDefault(import('../pages/organization/favorites/index.vue' /* webpackChunkName: "pages/organization/favorites/index" */))
const _78c27d1c = () => interopDefault(import('../pages/organization/managers.vue' /* webpackChunkName: "pages/organization/managers" */))
const _4a1cea75 = () => interopDefault(import('../pages/organization/managers/index.vue' /* webpackChunkName: "pages/organization/managers/index" */))
const _eb5f9466 = () => interopDefault(import('../pages/organization/profile.vue' /* webpackChunkName: "pages/organization/profile" */))
const _cd851ea2 = () => interopDefault(import('../pages/organization/settings.vue' /* webpackChunkName: "pages/organization/settings" */))
const _72be01c0 = () => interopDefault(import('../pages/organization/settings/card.vue' /* webpackChunkName: "pages/organization/settings/card" */))
const _1a5234b0 = () => interopDefault(import('../pages/organization/settings/privacy.vue' /* webpackChunkName: "pages/organization/settings/privacy" */))
const _b85b596e = () => interopDefault(import('../pages/organization/settings/profile.vue' /* webpackChunkName: "pages/organization/settings/profile" */))
const _22cf101c = () => interopDefault(import('../pages/organization/settings/index.vue' /* webpackChunkName: "pages/organization/settings/index" */))
const _6235435f = () => interopDefault(import('../pages/personal/add-event.vue' /* webpackChunkName: "pages/personal/add-event" */))
const _edda35d6 = () => interopDefault(import('../pages/personal/create-organization.vue' /* webpackChunkName: "pages/personal/create-organization" */))
const _23f25f10 = () => interopDefault(import('../pages/personal/events.vue' /* webpackChunkName: "pages/personal/events" */))
const _4eda51d6 = () => interopDefault(import('../pages/personal/events/creator.vue' /* webpackChunkName: "pages/personal/events/creator" */))
const _1e8407ce = () => interopDefault(import('../pages/personal/events/drafts.vue' /* webpackChunkName: "pages/personal/events/drafts" */))
const _a7a44bc8 = () => interopDefault(import('../pages/personal/events/participant.vue' /* webpackChunkName: "pages/personal/events/participant" */))
const _7d39c60a = () => interopDefault(import('../pages/personal/events/index.vue' /* webpackChunkName: "pages/personal/events/index" */))
const _9c5d6270 = () => interopDefault(import('../pages/personal/favorites.vue' /* webpackChunkName: "pages/personal/favorites" */))
const _23723136 = () => interopDefault(import('../pages/personal/favorites/company.vue' /* webpackChunkName: "pages/personal/favorites/company" */))
const _0c397e70 = () => interopDefault(import('../pages/personal/favorites/events.vue' /* webpackChunkName: "pages/personal/favorites/events" */))
const _b7d35800 = () => interopDefault(import('../pages/personal/favorites/volunteers.vue' /* webpackChunkName: "pages/personal/favorites/volunteers" */))
const _426cc16a = () => interopDefault(import('../pages/personal/favorites/index.vue' /* webpackChunkName: "pages/personal/favorites/index" */))
const _6cdfeea8 = () => interopDefault(import('../pages/personal/notify.vue' /* webpackChunkName: "pages/personal/notify" */))
const _40ce51aa = () => interopDefault(import('../pages/personal/notify/index.vue' /* webpackChunkName: "pages/personal/notify/index" */))
const _3eb1403e = () => interopDefault(import('../pages/personal/old-main.vue' /* webpackChunkName: "pages/personal/old-main" */))
const _b798114c = () => interopDefault(import('../pages/personal/profile.vue' /* webpackChunkName: "pages/personal/profile" */))
const _885c3e7c = () => interopDefault(import('../pages/personal/settings.vue' /* webpackChunkName: "pages/personal/settings" */))
const _94ca0cf6 = () => interopDefault(import('../pages/personal/settings/auth.vue' /* webpackChunkName: "pages/personal/settings/auth" */))
const _330f92bb = () => interopDefault(import('../pages/personal/settings/privacy.vue' /* webpackChunkName: "pages/personal/settings/privacy" */))
const _37e9ff48 = () => interopDefault(import('../pages/personal/settings/profile.vue' /* webpackChunkName: "pages/personal/settings/profile" */))
const _2b8de1ce = () => interopDefault(import('../pages/personal/settings/reviews.vue' /* webpackChunkName: "pages/personal/settings/reviews" */))
const _2e0c3e86 = () => interopDefault(import('../pages/personal/settings/subscribe.vue' /* webpackChunkName: "pages/personal/settings/subscribe" */))
const _792f4dc5 = () => interopDefault(import('../pages/personal/settings/index.vue' /* webpackChunkName: "pages/personal/settings/index" */))
const _59219ca8 = () => interopDefault(import('../pages/organization/manage-event/_id/index.vue' /* webpackChunkName: "pages/organization/manage-event/_id/index" */))
const _e66ef702 = () => interopDefault(import('../pages/personal/manage-event/_id/index.vue' /* webpackChunkName: "pages/personal/manage-event/_id/index" */))
const _5f8a0c20 = () => interopDefault(import('../pages/organization/manage-event/_id/edit.vue' /* webpackChunkName: "pages/organization/manage-event/_id/edit" */))
const _959cd2ae = () => interopDefault(import('../pages/organization/manage-event/_id/finish.vue' /* webpackChunkName: "pages/organization/manage-event/_id/finish" */))
const _4ab556e4 = () => interopDefault(import('../pages/organization/manage-event/_id/invites.vue' /* webpackChunkName: "pages/organization/manage-event/_id/invites" */))
const _031300a7 = () => interopDefault(import('../pages/organization/manage-event/_id/invites/events-list.vue' /* webpackChunkName: "pages/organization/manage-event/_id/invites/events-list" */))
const _17f1d2ec = () => interopDefault(import('../pages/organization/manage-event/_id/invites/favorites.vue' /* webpackChunkName: "pages/organization/manage-event/_id/invites/favorites" */))
const _37b62267 = () => interopDefault(import('../pages/organization/manage-event/_id/invites/index.vue' /* webpackChunkName: "pages/organization/manage-event/_id/invites/index" */))
const _61168288 = () => interopDefault(import('../pages/organization/manage-event/_id/moderation.vue' /* webpackChunkName: "pages/organization/manage-event/_id/moderation" */))
const _4754133b = () => interopDefault(import('../pages/organization/manage-event/_id/view.vue' /* webpackChunkName: "pages/organization/manage-event/_id/view" */))
const _d0bbd382 = () => interopDefault(import('../pages/organization/manage-event/_id/volunteers.vue' /* webpackChunkName: "pages/organization/manage-event/_id/volunteers" */))
const _570bbeb2 = () => interopDefault(import('../pages/organization/manage-event/_id/volunteers/accepted.vue' /* webpackChunkName: "pages/organization/manage-event/_id/volunteers/accepted" */))
const _323ae8d1 = () => interopDefault(import('../pages/organization/manage-event/_id/volunteers/all.vue' /* webpackChunkName: "pages/organization/manage-event/_id/volunteers/all" */))
const _0ea9e1ae = () => interopDefault(import('../pages/organization/manage-event/_id/volunteers/declined.vue' /* webpackChunkName: "pages/organization/manage-event/_id/volunteers/declined" */))
const _52d5fd60 = () => interopDefault(import('../pages/organization/manage-event/_id/volunteers/new.vue' /* webpackChunkName: "pages/organization/manage-event/_id/volunteers/new" */))
const _90a29cfc = () => interopDefault(import('../pages/organization/manage-event/_id/volunteers/index.vue' /* webpackChunkName: "pages/organization/manage-event/_id/volunteers/index" */))
const _4dbcda26 = () => interopDefault(import('../pages/personal/manage-event/_id/edit.vue' /* webpackChunkName: "pages/personal/manage-event/_id/edit" */))
const _27029e36 = () => interopDefault(import('../pages/personal/manage-event/_id/finish.vue' /* webpackChunkName: "pages/personal/manage-event/_id/finish" */))
const _130540f7 = () => interopDefault(import('../pages/personal/manage-event/_id/invites.vue' /* webpackChunkName: "pages/personal/manage-event/_id/invites" */))
const _448d663a = () => interopDefault(import('../pages/personal/manage-event/_id/invites/events-list.vue' /* webpackChunkName: "pages/personal/manage-event/_id/invites/events-list" */))
const _21de813f = () => interopDefault(import('../pages/personal/manage-event/_id/invites/favorites.vue' /* webpackChunkName: "pages/personal/manage-event/_id/invites/favorites" */))
const _aff61b8c = () => interopDefault(import('../pages/personal/manage-event/_id/invites/index.vue' /* webpackChunkName: "pages/personal/manage-event/_id/invites/index" */))
const _5b2d37c9 = () => interopDefault(import('../pages/personal/manage-event/_id/moderation.vue' /* webpackChunkName: "pages/personal/manage-event/_id/moderation" */))
const _7e28cbf0 = () => interopDefault(import('../pages/personal/manage-event/_id/view.vue' /* webpackChunkName: "pages/personal/manage-event/_id/view" */))
const _235a8f4c = () => interopDefault(import('../pages/personal/manage-event/_id/volunteers.vue' /* webpackChunkName: "pages/personal/manage-event/_id/volunteers" */))
const _15f4863a = () => interopDefault(import('../pages/personal/manage-event/_id/volunteers/accepted.vue' /* webpackChunkName: "pages/personal/manage-event/_id/volunteers/accepted" */))
const _6873d944 = () => interopDefault(import('../pages/personal/manage-event/_id/volunteers/all.vue' /* webpackChunkName: "pages/personal/manage-event/_id/volunteers/all" */))
const _50244741 = () => interopDefault(import('../pages/personal/manage-event/_id/volunteers/declined.vue' /* webpackChunkName: "pages/personal/manage-event/_id/volunteers/declined" */))
const _70202bdd = () => interopDefault(import('../pages/personal/manage-event/_id/volunteers/new.vue' /* webpackChunkName: "pages/personal/manage-event/_id/volunteers/new" */))
const _1b196ccf = () => interopDefault(import('../pages/personal/manage-event/_id/volunteers/index.vue' /* webpackChunkName: "pages/personal/manage-event/_id/volunteers/index" */))
const _6d2b0f44 = () => interopDefault(import('../pages/events/_id.vue' /* webpackChunkName: "pages/events/_id" */))
const _1ba1f8fe = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _308d20a4 = () => interopDefault(import('../pages/social/_provider.vue' /* webpackChunkName: "pages/social/_provider" */))
const _268b8e82 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5fbac9ed = () => interopDefault(import('../pages/_profile/index.vue' /* webpackChunkName: "pages/_profile/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '',
  linkExactActiveClass: '',
  scrollBehavior,

  routes: [{
    path: "/404/",
    component: _728a9c30,
    pathToRegexpOptions: {"strict":true},
    name: "404"
  }, {
    path: "/about/",
    component: _06d3b3bd,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/contacts/",
    component: _726aa35a,
    pathToRegexpOptions: {"strict":true},
    name: "contacts"
  }, {
    path: "/events/",
    component: _02e57448,
    pathToRegexpOptions: {"strict":true},
    name: "events"
  }, {
    path: "/login/",
    component: _1f999cce,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/news/",
    component: _2a1eadd4,
    pathToRegexpOptions: {"strict":true},
    name: "news"
  }, {
    path: "/organization/",
    component: _19752b76,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "news/",
      component: _91588f02,
      pathToRegexpOptions: {"strict":true},
      name: "organization-index-news"
    }, {
      path: "news/create/",
      component: _5ad7a21f,
      pathToRegexpOptions: {"strict":true},
      name: "organization-index-news-create"
    }, {
      path: "news/:id?/",
      component: _748e7bea,
      pathToRegexpOptions: {"strict":true},
      name: "organization-index-news-id"
    }, {
      path: "",
      component: _e0d67d0e,
      pathToRegexpOptions: {"strict":true},
      name: "organization-index"
    }]
  }, {
    path: "/personal/",
    component: _96f4277a,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "news/",
      component: _10e734dc,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index-news"
    }, {
      path: "news/create/",
      component: _21b4976c,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index-news-create"
    }, {
      path: "news/:id?/",
      component: _5fb4677d,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index-news-id"
    }, {
      path: "",
      component: _b11a16f4,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index"
    }]
  }, {
    path: "/organization/add-event/",
    component: _323bb112,
    pathToRegexpOptions: {"strict":true},
    name: "organization-add-event"
  }, {
    path: "/organization/events/",
    component: _26ff77a5,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "created/",
      component: _3c2641fe,
      pathToRegexpOptions: {"strict":true},
      name: "organization-events-created"
    }, {
      path: "drafts/",
      component: _57a0584c,
      pathToRegexpOptions: {"strict":true},
      name: "organization-events-drafts"
    }, {
      path: "",
      component: _450a2330,
      pathToRegexpOptions: {"strict":true},
      name: "organization-events"
    }]
  }, {
    path: "/organization/favorites/",
    component: _fc50870a,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "events/",
      component: _67fe5d46,
      pathToRegexpOptions: {"strict":true},
      name: "organization-favorites-events"
    }, {
      path: "volunteers/",
      component: _8e1f2f26,
      pathToRegexpOptions: {"strict":true},
      name: "organization-favorites-volunteers"
    }, {
      path: "",
      component: _1685babe,
      pathToRegexpOptions: {"strict":true},
      name: "organization-favorites"
    }]
  }, {
    path: "/organization/managers/",
    component: _78c27d1c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _4a1cea75,
      pathToRegexpOptions: {"strict":true},
      name: "organization-managers"
    }]
  }, {
    path: "/organization/profile/",
    component: _eb5f9466,
    pathToRegexpOptions: {"strict":true},
    name: "organization-profile"
  }, {
    path: "/organization/settings/",
    component: _cd851ea2,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "card/",
      component: _72be01c0,
      pathToRegexpOptions: {"strict":true},
      name: "organization-settings-card"
    }, {
      path: "privacy/",
      component: _1a5234b0,
      pathToRegexpOptions: {"strict":true},
      name: "organization-settings-privacy"
    }, {
      path: "profile/",
      component: _b85b596e,
      pathToRegexpOptions: {"strict":true},
      name: "organization-settings-profile"
    }, {
      path: "",
      component: _22cf101c,
      pathToRegexpOptions: {"strict":true},
      name: "organization-settings"
    }]
  }, {
    path: "/personal/add-event/",
    component: _6235435f,
    pathToRegexpOptions: {"strict":true},
    name: "personal-add-event"
  }, {
    path: "/personal/create-organization/",
    component: _edda35d6,
    pathToRegexpOptions: {"strict":true},
    name: "personal-create-organization"
  }, {
    path: "/personal/events/",
    component: _23f25f10,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "creator/",
      component: _4eda51d6,
      pathToRegexpOptions: {"strict":true},
      name: "personal-events-creator"
    }, {
      path: "drafts/",
      component: _1e8407ce,
      pathToRegexpOptions: {"strict":true},
      name: "personal-events-drafts"
    }, {
      path: "participant/",
      component: _a7a44bc8,
      pathToRegexpOptions: {"strict":true},
      name: "personal-events-participant"
    }, {
      path: "",
      component: _7d39c60a,
      pathToRegexpOptions: {"strict":true},
      name: "personal-events"
    }]
  }, {
    path: "/personal/favorites/",
    component: _9c5d6270,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "company/",
      component: _23723136,
      pathToRegexpOptions: {"strict":true},
      name: "personal-favorites-company"
    }, {
      path: "events/",
      component: _0c397e70,
      pathToRegexpOptions: {"strict":true},
      name: "personal-favorites-events"
    }, {
      path: "volunteers/",
      component: _b7d35800,
      pathToRegexpOptions: {"strict":true},
      name: "personal-favorites-volunteers"
    }, {
      path: "",
      component: _426cc16a,
      pathToRegexpOptions: {"strict":true},
      name: "personal-favorites"
    }]
  }, {
    path: "/personal/notify/",
    component: _6cdfeea8,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _40ce51aa,
      pathToRegexpOptions: {"strict":true},
      name: "personal-notify"
    }]
  }, {
    path: "/personal/old-main/",
    component: _3eb1403e,
    pathToRegexpOptions: {"strict":true},
    name: "personal-old-main"
  }, {
    path: "/personal/profile/",
    component: _b798114c,
    pathToRegexpOptions: {"strict":true},
    name: "personal-profile"
  }, {
    path: "/personal/settings/",
    component: _885c3e7c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "auth/",
      component: _94ca0cf6,
      pathToRegexpOptions: {"strict":true},
      name: "personal-settings-auth"
    }, {
      path: "privacy/",
      component: _330f92bb,
      pathToRegexpOptions: {"strict":true},
      name: "personal-settings-privacy"
    }, {
      path: "profile/",
      component: _37e9ff48,
      pathToRegexpOptions: {"strict":true},
      name: "personal-settings-profile"
    }, {
      path: "reviews/",
      component: _2b8de1ce,
      pathToRegexpOptions: {"strict":true},
      name: "personal-settings-reviews"
    }, {
      path: "subscribe/",
      component: _2e0c3e86,
      pathToRegexpOptions: {"strict":true},
      name: "personal-settings-subscribe"
    }, {
      path: "",
      component: _792f4dc5,
      pathToRegexpOptions: {"strict":true},
      name: "personal-settings"
    }]
  }, {
    path: "/organization/manage-event/:id?/",
    component: _59219ca8,
    pathToRegexpOptions: {"strict":true},
    name: "organization-manage-event-id"
  }, {
    path: "/personal/manage-event/:id?/",
    component: _e66ef702,
    pathToRegexpOptions: {"strict":true},
    name: "personal-manage-event-id"
  }, {
    path: "/organization/manage-event/:id?/edit/",
    component: _5f8a0c20,
    pathToRegexpOptions: {"strict":true},
    name: "organization-manage-event-id-edit"
  }, {
    path: "/organization/manage-event/:id?/finish/",
    component: _959cd2ae,
    pathToRegexpOptions: {"strict":true},
    name: "organization-manage-event-id-finish"
  }, {
    path: "/organization/manage-event/:id?/invites/",
    component: _4ab556e4,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "events-list/",
      component: _031300a7,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-invites-events-list"
    }, {
      path: "favorites/",
      component: _17f1d2ec,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-invites-favorites"
    }, {
      path: "",
      component: _37b62267,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-invites"
    }]
  }, {
    path: "/organization/manage-event/:id?/moderation/",
    component: _61168288,
    pathToRegexpOptions: {"strict":true},
    name: "organization-manage-event-id-moderation"
  }, {
    path: "/organization/manage-event/:id?/view/",
    component: _4754133b,
    pathToRegexpOptions: {"strict":true},
    name: "organization-manage-event-id-view"
  }, {
    path: "/organization/manage-event/:id?/volunteers/",
    component: _d0bbd382,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "accepted/",
      component: _570bbeb2,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-volunteers-accepted"
    }, {
      path: "all/",
      component: _323ae8d1,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-volunteers-all"
    }, {
      path: "declined/",
      component: _0ea9e1ae,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-volunteers-declined"
    }, {
      path: "new/",
      component: _52d5fd60,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-volunteers-new"
    }, {
      path: "",
      component: _90a29cfc,
      pathToRegexpOptions: {"strict":true},
      name: "organization-manage-event-id-volunteers"
    }]
  }, {
    path: "/personal/manage-event/:id?/edit/",
    component: _4dbcda26,
    pathToRegexpOptions: {"strict":true},
    name: "personal-manage-event-id-edit"
  }, {
    path: "/personal/manage-event/:id?/finish/",
    component: _27029e36,
    pathToRegexpOptions: {"strict":true},
    name: "personal-manage-event-id-finish"
  }, {
    path: "/personal/manage-event/:id?/invites/",
    component: _130540f7,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "events-list/",
      component: _448d663a,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-invites-events-list"
    }, {
      path: "favorites/",
      component: _21de813f,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-invites-favorites"
    }, {
      path: "",
      component: _aff61b8c,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-invites"
    }]
  }, {
    path: "/personal/manage-event/:id?/moderation/",
    component: _5b2d37c9,
    pathToRegexpOptions: {"strict":true},
    name: "personal-manage-event-id-moderation"
  }, {
    path: "/personal/manage-event/:id?/view/",
    component: _7e28cbf0,
    pathToRegexpOptions: {"strict":true},
    name: "personal-manage-event-id-view"
  }, {
    path: "/personal/manage-event/:id?/volunteers/",
    component: _235a8f4c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "accepted/",
      component: _15f4863a,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-volunteers-accepted"
    }, {
      path: "all/",
      component: _6873d944,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-volunteers-all"
    }, {
      path: "declined/",
      component: _50244741,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-volunteers-declined"
    }, {
      path: "new/",
      component: _70202bdd,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-volunteers-new"
    }, {
      path: "",
      component: _1b196ccf,
      pathToRegexpOptions: {"strict":true},
      name: "personal-manage-event-id-volunteers"
    }]
  }, {
    path: "/events/:id/",
    component: _6d2b0f44,
    pathToRegexpOptions: {"strict":true},
    name: "events-id"
  }, {
    path: "/news/:id/",
    component: _1ba1f8fe,
    pathToRegexpOptions: {"strict":true},
    name: "news-id"
  }, {
    path: "/social/:provider?/",
    component: _308d20a4,
    pathToRegexpOptions: {"strict":true},
    name: "social-provider"
  }, {
    path: "/",
    component: _268b8e82,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:profile/",
    component: _5fbac9ed,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "*",
    component: _728a9c30,
    name: "no-founded"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
