

export default async (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      // if (to.params.savePosition) return {};

      const findEl = (hash, x = 0) => {
        return (
          document.querySelector(hash) ||
          new Promise((resolve, reject) => {
            if (x > 50) {
              return resolve(undefined);
            }
            setTimeout(() => {
              resolve(findEl(hash, ++x || 1));
            }, 100);
          }));
      };

      if (to.hash && to.hash !== '#organizations' && to.hash !== '#volunteers') {
        const el = await findEl(to.hash);
        if (el) {
          // @ts-ignore
          return window.$nuxt.$smoothScroll ?
          // @ts-ignore
          window.$nuxt.$smoothScroll({ scrollTo: el }) :
          window.scrollTo(0, el.offsetTop);
        } else {
          // eslint-disable-next-line no-console
          console.error(`page dont have block with ${to.hash}`);
        }
      }

      return { x: 0, y: 0 };
    }
