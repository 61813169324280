var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',{staticClass:"subscribe-form"},[_c('p',{staticClass:"subscribe-form__title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),(!_vm.isSubscribe)?_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"subscribe-form__row"},[_c('ValidationProvider',{staticClass:"label",attrs:{"name":"Email","rules":{ required: true, email: true },"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var untouched = ref.untouched;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subscribeEmail),expression:"subscribeEmail"}],staticClass:"input",class:{
                'input--error':
                  !untouched && errors.length && _vm.subscribeEmail.length,
              },attrs:{"type":"text","placeholder":"Укажите свой e-mail адрес"},domProps:{"value":(_vm.subscribeEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.subscribeEmail=$event.target.value}}}),_vm._v(" "),(!untouched && errors.length && _vm.subscribeEmail.length)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('buttonV',{attrs:{"tag-name":"button","title":"Подписаться","type":"submit","class-mod":"subscribe-form__btn btn-green"}})],1),_vm._v(" "),_c('p',{staticClass:"subscribe-form__confirm",domProps:{"innerHTML":_vm._s(_vm.note)}})])]}}],null,false,116479775)}):_c('div',{staticClass:"subscribe-form__succses"},[_c('p',[_vm._v("\n        Вы успешно подписались на нашу рассылку."),_c('br'),_vm._v("\n        Проверяйте почту, читайте интересные статьи, не пропускайте наши\n        мероприятия.\n      ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }