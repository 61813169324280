






import { ROUTER_LINK } from '@/constants/constList';
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class Logo extends Vue {
  @Prop({ type: [String, Object], default: '' })
  classList!: any;

  @Prop({ type: String, required: true })
  image!: string;

  altImg: string = 'Sofia';

  mainLink: string = ROUTER_LINK.main;
};
