









import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({
  name: 'ContainerSm',
})
export default class ContainerSm extends Vue {
  @Prop({ type: String, default: '' })
  classComponent!: string;

  @Prop({ type: String, default: 'div' })
    tagName!: string;
};
