
























































import { Component, mixins, Prop } from 'nuxt-property-decorator';
import ModalBody from '~/components/Modals/ModalBody/ModalBody.vue';
import BackendHelpers from '~/mixins/use-backend';

@Component({
  name: 'ModalPhoneConfirmCode',
  components: {
    ModalBody,
  },
})
export default class ModalPhoneConfirm extends mixins(BackendHelpers) {
  title = 'Код безопасности';

  errorsRequest = [];

  code = '';

  timeLeft = 120;

  timer: undefined | number = undefined;

  isTimeLeftZero = true;

  async submitCode() {
    const request = this.$api.auth.verifyCode(this.code, this.$typedStore.state.socialAuth.socialAuthAccessToken);
    const token = await this.makeRequest(() => request);
    if (token) {
      await this.$auth.setUserToken(token);
      await this.$typedStore.commit('socialAuth/clearState');
      await this.$typedStore.dispatch('organization/getOrganizations');
    }
    await this.$router.push('/personal/');
    this.$toast.success('Добро пожаловать!');
  }

  async handleGetCode() {
    const { phone } = this.$typedStore.state.socialAuth;
    await this.$api.auth.sendCode({ phone });
    this.$toast.info('На ваш мобильный телефон повторно отправлен код подтверждения.')
    this.startTimer();
  }

  startTimer() {
    if (!this.timer) {
      this.isTimeLeftZero = false;
      this.timer = setInterval(() => {
        this.timeLeft -= 1;
        if (this.timeLeft === 0) {
          clearInterval(this.timer);
          this.isTimeLeftZero = true;
        }
      }, 1000) as unknown as number;
    }
  }

  mounted() {
    this.startTimer();
  }
};
