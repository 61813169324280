import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { formatedImageUrl } from '~/constants/constFormated';
import { OptionsCookies } from '~/constants/constList';
import { StateInterface } from '~/store/index';
import { AxiosResponse } from 'axios';
import { Organization, OrganizationUser, OrganizationUserRole } from '~/declarations/organizations';
import { Results } from '~/declarations/shared';

export interface OrganizationStateInterface {
  organizations: Record<Organization['id'], Organization>;
  // format {id: {}; id: {}}
  isOrganization: boolean; // if have users Organizations;
  activeOrganization: Organization['id'] | null; // format id_organization;
  activeOrganizationMembers: Record<OrganizationUser['user'], OrganizationUser>;
  activeOrganizationRole: OrganizationUserRole | null;
}

export const state = (): OrganizationStateInterface => ({
  organizations: {},
  // format {id: {}, id: {}}
  isOrganization: false, // if have users Organizations;
  activeOrganization: null, // format id_organization;
  activeOrganizationMembers: {},
  activeOrganizationRole: null,
});

export const mutations: MutationTree<OrganizationStateInterface> = {
  setOrganizations(state, organizations) {
    state.organizations = Object.assign({}, organizations);
  },
  setActiveOrganization(state, idOrg) {
    if (state.organizations[idOrg]) {
      state.activeOrganization = idOrg;
      state.isOrganization = true;
      // @ts-ignore
      this.$cookies.set('organizationActive', idOrg, OptionsCookies);
    }
  },
  setActiveOrganizationMembers(state, member) {
    // @ts-ignore
    this._vm.$set(state, 'activeOrganizationMembers', member);
  },
  setActiveOrganizationRole(state, role) {
    state.activeOrganizationRole = role;
  },
  removeActiveOrganizationMember(state, memberId) {
    if (state.activeOrganizationMembers[memberId]) {
      const obj = Object.assign({}, state.activeOrganizationMembers);
      delete obj[memberId];
      // @ts-ignore
      this._vm.$set(state, 'activeOrganizationMembers', obj);
    }
  },

  updateOrganization(state, newOrganization) {
    state.organizations = Object.assign({}, state.organizations, {
      [newOrganization.id]: newOrganization,
    });
  },
  clearActiveOrganization(state) {
    state.activeOrganization = null;
    state.isOrganization = false;
    state.activeOrganizationMembers = {};
    // @ts-ignore
    this.$cookies.remove('organizationActive');
  },
  clearOrganizations(state) {
    state.organizations = {};
    state.isOrganization = false;
    state.activeOrganization = null;
    state.activeOrganizationMembers = {};
    // @ts-ignore
    this.$cookies.remove('organizationActive');
  },
};

export const actions: ActionTree<OrganizationStateInterface, StateInterface> = {
  logout({ commit }) {
    commit('clearOrganizations');
  },
  async getOrganizations({ commit }) {
    if (this.$auth.loggedIn) {
      const user = this.$auth.user;
      const organizationsId = (user?.organization_ids || []) as Array<Organization['id']>;
      // const organizationsId = [2]; // зашил пока вместо id приходит id таблицы OrzanizationUser

      try {
        const organizations: OrganizationStateInterface['organizations'] = {};
        for (let i = 0; i < organizationsId.length; i++) {
          const { data: organization } = await this.$axios.get(
            `/api/organizations/${organizationsId[i]}/`,
          );
          organizations[organization.id] = organization;
        }

        commit('setOrganizations', organizations);
      } catch (e) {
        console.log(e);
      }
    }
  },
  async setActiveOrganization({ rootState, state, commit }, id) {
    if (state.organizations[id]) {
      try {
        commit('setActiveOrganization', id);
        const resultMember = await this.$axios.get(
          `/api/organization_users/${id}/`,
        ) as AxiosResponse<Results<OrganizationUser>>;
        // @ts-ignore
        const user = rootState.auth.user;
        const members = resultMember.data.results.reduce((acc: OrganizationStateInterface['activeOrganizationMembers'], el) => {
          acc[el.user] = el;
          if (user.id === el.user) {
            commit('setActiveOrganizationRole', el.role);
          }
          return acc;
        }, {});
        commit('setActiveOrganizationMembers', members);
      } catch (e) {
        console.log(e);
      }
    }
  },
  async updateActiveOrganization({ state, commit }, id) {
    if (state.organizations[id]) {
      try {
        const { data: organization } = await this.$axios.get(
          `/api/organizations/${id}/`,
        );

        commit('updateOrganization', organization);
      } catch (e) {
        console.log(e);
      }
    }
  },

  async updateActiveOrganizationMembers({ rootState, state, commit }) {
    if (state.activeOrganization) {
      try {
        const resultMember = await this.$axios.get(
          `/api/organization_users/${state.activeOrganization}/`,
        ) as AxiosResponse<Results<OrganizationUser>>;
        // @ts-ignore
        const user = rootState.auth.user;
        const members = resultMember.data.results.reduce((acc: OrganizationStateInterface['activeOrganizationMembers'], el) => {
          acc[el.user] = el;
          if (user.id === el.user) {
            commit('setActiveOrganizationRole', el.role);
          }
          return acc;
        }, {});
        commit('setActiveOrganizationMembers', members);
      } catch (e) {
        console.log(e);
      }
    }
  },

  async removeActiveOrganizationMember({ state, commit }, memberId) {
    if (state.activeOrganizationMembers[memberId]) {
      try {
        await this.$axios.post(
          `/api/delete-organization-member/${state.activeOrganizationMembers[memberId].id}/`,
        );
        commit('removeActiveOrganizationMember', memberId);
      } catch (e) {
        console.log(e);
      }
    }
  },
};

export const getters: GetterTree<OrganizationStateInterface, StateInterface> = {
  getAvatars(state) {
    return Object.values(state.organizations).map((org) => {
      const logo = formatedImageUrl(org.organization_profile.logo);
      return {
        id: org.id,
        is_accepted: org.is_accepted,
        nameUser: org.name || '',
        image: {
          url: logo, // url avatar
          alt: org.name || '',
        },
        svgIcon: {
          name: 'organization',
          stroke: '#838383',
        },
      };
    });
  },
  getActiveAvatar(state) {
    if (state.activeOrganization) {
      const organization = state.organizations[state.activeOrganization];
      const url = organization.organization_profile
        ? organization.organization_profile.logo
        : '';
      const logo = formatedImageUrl(url);
      return {
        id: state.activeOrganization,
        nameUser: organization.name || '',
        image: {
          url: logo, // url avatar
          alt: organization.name || '',
        },
        svgIcon: {
          name: 'organization',
          stroke: '#838383',
        },
      };
    }
    return null;
  },
  getActiveOrganization(state) {
    if (state.activeOrganization) {
      return state.organizations[state.activeOrganization];
    }

    return null;
  },
};
