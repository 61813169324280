










import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class AuthLink extends Vue {
  @Prop({ type: String, default: '' })
  url!: string;

  @Prop({ type: String, required:true })
  label!: string;

  handlerOpenModal() {
    this.$emit('openModalAuth');
  }
};
