

























// styles
// import 'assets/scss/_common.scss';

import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import BreadCrumbs from '@/components/ui/BreadCrumbs.vue';
import { Component, mixins } from 'nuxt-property-decorator';
import HasHeaderData from '@/mixins/has-header-data';

@Component({
  name: 'Personal',
  components: {
    Header,
    Footer,
    BreadCrumbs,
    PersonalHeader: () => import('@/components/Personal/PersonalHeader.vue'),
  },
})
export default class Personal extends mixins(HasHeaderData) {
  async fetch() {
    await this.$typedStore.dispatch('enums/getProfileEnums');
  }
};
