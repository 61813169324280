























import ContainerSm from '@/components/ui/ContainerSm.vue';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { Breadcrumb } from '~/declarations/shared';

@Component({
  name: 'BreadCrumbs',
  components: {
    ContainerSm,
  },
  props: {
    classMod: {
      type: String,
      default: '',
    },
  },
  filters: {
    unpackTitle(title: string | (() => string)) {
      if (typeof title === 'function') {
        return title();
      } else {
        return title;
      }
    },
  },
})
export default class BreadCrumbs extends Vue {
  @Prop({ default: () => [] })
  breadcrumbLinks!: Array<Breadcrumb>;

  linkUrl(url, name) {
    if (!url) {
      return { name };
    }
    if (typeof url === 'function') {
      const urlRes = url.bind(this);
      return urlRes();
    }
    return url;
  }
};
