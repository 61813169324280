<template>
  <component :is="tagsBlock" class="card-user" :class="classMod" v-bind="userLink">
    <div class="card-user__icon-block">
      <img v-if="image && image.url" :src="image.url" :alt="image.alt" />
      <span v-else-if="initial.length">{{ initial }}</span>
      <svg-icon v-else v-bind="svgIcon" />
    </div>
    <p v-if="!onlyIcon" class="card-user__author-name" :class="{ _row: isRow }">
      {{ nameUser }}
    </p>
  </component>
</template>

<script>
export default {
  props: {
    classMod: {
      type: [String, Object],
      default: '',
    },
    nameUser: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      default: undefined,
    },
    initial: {
      type: String,
      default: '',
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    tagsBlock: {
      type: String,
      default: 'div',
    },
    linkTo: {
      type: String,
      default: '',
    },
    svgIcon: {
      type: Object,
      default: () => ({
        name: 'user',
      }),
    },
    isRow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userLink() {
      if (this.tagsBlock === 'NuxtLink') {
        return {
          to: this.linkTo,
        };
      }
      if (this.tagsBlock === 'a') {
        return {
          href: this.linkTo,
        };
      }
      return {};
    },
  },
};
</script>

<style lang="scss">
a.card-user {
  text-decoration: none;
  &:hover {
    color: $color-green;
  }
}
.card-user {
  width: 100%;
  display: flex;
  align-items: center;

  color: $color-default;

  &._active {
    opacity: 0.5;
  }

  &._hover {
    cursor: pointer;

    &:hover {
      @include mediaMin($sm) {
        opacity: 1;
        color: $color-green;

        & .card-user__icon-block {
          & svg {
            color: inherit;
            stroke: currentColor;
          }
        }
      }
    }
  }

  &__icon-block {
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: inherit;

    border-radius: 50%;
    background-color: $color-light;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }

    & svg {
      color: inherit;
      width: 20px;
      height: 20px;
    }

    & span {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: inherit;
    }
  }

  &__author-name {
    max-width: calc(100% - 50px);
    margin: 0;
    padding: 0 0 0 10px;

    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: inherit;

    transition: all 0.3s ease;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    @include mediaMax($sm) {
      &._row {
        padding: 0 50px 0 10px;
      }
    }
  }

  &._big {
    & .card-user__icon-block {
      width: 75px;
      height: 75px;

      // border: 1px solid currentColor;

      & svg {
        color: inherit;
        stroke: $color-grey-1;
        width: 28px;
        height: 28px;
      }

      & span {
        font-size: 34px;
        line-height: 45px;
        color: $color-grey-1;
      }
    }

    & .card-user__author-name {
      padding-left: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      @include mediaMax($xs) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>
