























// styles
// import 'assets/scss/_common.scss';

import { Component, mixins } from 'nuxt-property-decorator';
import Header from '~/components/Header/Header.vue';
import Footer from '~/components/Footer/Footer.vue';
import HasHeaderData from '~/mixins/has-header-data';
import Lk from '~/mixins/lk';

@Component({
  name: 'OrganizationProfile',
  components: {
    Header,
    Footer,
    BreadCrumbs: () => import('@/components/ui/BreadCrumbs.vue'),
    PersonalHeader: () => import('@/components/Personal/PersonalHeader.vue'),
    PersonalStartInfo: () =>
      import('@/components/Personal/PersonalStartInfo.vue'),
  },
})
export default class OrganizationProfile extends mixins(HasHeaderData, Lk) {
  get profileOrg() {
    return this.$typedStore.getters['organization/getActiveAvatar'] || {};
  }

  get profileStatistic() {
    return this.$typedStore.state.profileInfoStatistic.statisticOrg || [];
  }

  mounted() {
    const isActiveOrg = this.$typedStore.state.organization.activeOrganization;
    if (!isActiveOrg) {
      this.$toast.show('Не выбран кабинет Организации!');
      this.$router.push('/');
    }
    const hash = this.$route.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) this.$smoothScroll({ scrollTo: element });
    }
  }
};
