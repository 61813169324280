/*
TODO
[ ] -  переделать окончание слов на функцию declOfNum

*/

<template>
  <div class="search">
    <ContainerSm class="header">
      <div class="search__inner">
        <div class="search__input">
          <svg-icon name="search" width="19px" height="19px" stroke="#202020" />
          <input v-model="search" type="search" placeholder="Поиск..." />
        </div>
        <button class="btn btn-close" @click="handlerCloseSearch">
          <svg-icon
            name="close"
            width="18.5px"
            height="18.5px"
            stroke="#838383"
          ></svg-icon>
        </button>
      </div>
    </ContainerSm>
    <div class="search__result">
      <ContainerSm>
        <LoaderV v-if="isLoading"></LoaderV>
        <div v-if="isResult" class="search__result-top">
          <h2 class="search__result-title title-lg">Найдено</h2>
          <p class="search__result-text">
            {{ filteredList.length }} <span>{{ getProjectWord() }}</span> по
            запросу <span v-if="search !== ''">«{{ search }}»</span>
          </p>
        </div>
      </ContainerSm>
      <Container v-if="filteredList.length" class="search__result-cards">
        <ul class="cards">
          <Card
            v-for="event in filteredList"
            :key="event.id"
            :event="event"
            tag-name="li"
            @closeSearch="handlerCloseSearch"
          ></Card>
        </ul>
      </Container>
    </div>
  </div>
</template>

<script>
import Container from '@/components/ui/Container.vue';
import ContainerSm from '@/components/ui/ContainerSm.vue';
import Card from '@/components/ui/Card.vue';
import LoaderV from '@/components/ui/LoaderV.vue';

import { declOfNum } from '@/constants/constFormated';

export default {
  name: 'SearchLink',
  components: {
    Container,
    ContainerSm,
    Card,
    LoaderV,
  },
  data() {
    return {
      isResult: false,
      search: '',
      searchTimer: null,
      timeRequest: 300,
      titlesProject: ['проект', 'проектa', 'проектов'],
      filteredList: [],
      isLoading: false,
    };
  },
  watch: {
    search(val) {
      if (val.length >= 3) {
        this.isLoading = true;

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          this.showFilteredList();
        }, this.timeRequest);
      } else {
        this.isResult = false;
        this.filteredList = [];
      }
    },
  },
  methods: {
    async showFilteredList() {
      try {
        const result = await this.$axios.get(
          `/api/events/?search=${this.search}`
        );
        this.filteredList = result.data.results;
        this.isResult = true;
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isLoading = false;
      }
    },
    getProjectWord() {
      return declOfNum(this.filteredList.length, this.titlesProject);
    },
    handlerCloseSearch() {
      this.$emit('closeSearch');
    },
  },
};
</script>

<style lang="scss" >
@import 'modal.scss';
</style>
