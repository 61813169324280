import Quasar from 'quasar';
import langRu from 'quasar/lang/ru';
import { Vue } from 'nuxt-property-decorator';

Vue.use(Quasar, {
  lang: langRu,
  config: {
    loadingBar: {
      skipHijack: true,
    },
  },
});
