


















































































































import SocialsAuth from '@/components/Socials/SocialsAuth.vue';
import ModalBody from '@/components/Modals/ModalBody/ModalBody.vue';

import { phoneMask, unmask } from '@/constants/constFormated';
import { AUTH_COMPONENTS } from '@/constants/constList';
import { resetShowPassword, toggleShowPassword } from '@/constants/constFunctions';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { SocialAuthType, SocialNetwork } from '~/declarations/shared';
import { User } from '@sentry/vue';


const Socials: Array<SocialNetwork> = [
  {
    name: 'social-ya',
    nameHv: 'yandex-hv',
    type: 'yandex',
    // url: '',
    width: 11,
    height: 16,
  },
  // {
  //   name: 'social-gm',
  //   nameHv: 'gmail-hv',
  //   url: '',
  //   width: 16,
  //   height: 13,
  // },
  {
    name: 'social-mail',
    nameHv: 'mail-hv',
    type: 'mail',
    // url: '',
    width: 15,
    height: 15,
  },
  {
    name: 'social-vk',
    nameHv: 'vk-hv',
    type: 'VK',
    // url: '',
    width: 19,
    height: 11,
  },
  {
    name: 'social-google',
    nameHv: 'google-hv',
    type: 'google',
    // url: '',
    width: 16,
    height: 16,
  },
  {
    name: 'social-ok',
    nameHv: 'ok-hv',
    type: 'odnoklassniki',
    // url: '',
    width: 16,
    height: 16,
  },
  {
    name: 'social-fb',
    nameHv: 'fb-hv',
    type: 'facebook',
    // url: '',
    width: 8.5,
    height: 175,
  },
];

@Component({
  name: 'ModalLogin',
  components: {
    SocialsAuth,
    ModalBody,
  },
  methods: {
    toggleShowPassword,
    resetShowPassword,
  },
})
export default class ModalLogin extends Vue {
  @Prop({ type: Object })
  params!: { allowedSocials?: Array<SocialAuthType> } | null;

  socials: Array<SocialNetwork> = Socials;

  title: string = 'Вход';

  login: string = '';

  pass: string = '';

  remember: boolean = false;

  phoneMask = phoneMask;

  errorsRequest: Array<string> = [];

  get filteredSocials(): Array<SocialNetwork> {
    if (this.params?.allowedSocials) {
      return this.socials.filter(({ type }) => this.params?.allowedSocials?.includes((type)));
    }

    return this.socials;
  }

  closeModal() {
    this.$emit('closeModal');
  }

  handlerOpenModalPass() {
    this.$emit('openModalNext', { modal: AUTH_COMPONENTS.reset });
  }

  handlerOpenModalRegister() {
    this.$emit('openModalNext', { modal: AUTH_COMPONENTS.registration });
  }

  async submitAuth() {
    const data = {
      username: `${unmask(this.login, phoneMask)}`,
      password: this.pass,
    };

    try {
      await this.$auth.loginWith('local', { data });
      this.errorsRequest = [];
      if (this.$auth.user) {
        this.$sentry.setUser({
          id: this.$auth.user.id.toString(),
          username: this.$auth.user.username,
        });
      }
      this.$toast.success('Успешный вход!');
      this.$store.dispatch('userFavorites/getFavorites');
      this.$store.dispatch('userSubscribe/getSubscribe');
      this.$store.dispatch('organization/getOrganizations');
      this.closeModal();
    } catch (e) {
      if (
        (e?.response?.status && e.response.status === 400) ||
        e.response.status === 401
      ) {
        this.errorsRequest = ['Неправильный логин или пароль'];
      } else {
        console.log(e);
      }
    }
  }
};
