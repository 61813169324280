<template>
  <component :is="tagName" class="btn" :class="classMod" v-bind="linkOfTagName">
    <span>{{ title }}</span>
    <svg-icon v-if="svgIcon" v-bind="svgIcon"></svg-icon>
  </component>
</template>

<script>
export default {
  name: 'ButtonV',
  props: {
    href: {
      type: String,
      default: null,
    },
    classMod: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    svgIcon: {
      type: Object,
      default: undefined,
    },
    tagName: {
      type: String,
      default: 'a',
    },
  },
  computed: {
    linkOfTagName() {
      if (this.tagName === 'NuxtLink') {
        return {
          to: this.href,
        };
      }

      return {
        href: this.href,
      };
    },
  },
};
</script>