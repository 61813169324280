<template>
  <ul :class="classList">
    <!-- <li v-for="link in navList" :key="link.id">
      <NuxtLink
        v-if="link.url === '/about/'"
        v-slot="{ href, navigate, isExactActive }"
        :to="{ path: link.url, hash: '#info' }"
        class="main-nav__link"
        custom
      >
        <a
          :href="href"
          :class="[isExactActive && '_active', classLink]"
          @click="navigate"
          >{{ link.title }}</a
        >
      </NuxtLink>
      <NuxtLink
        v-else
        v-slot="{ href, navigate, isExactActive }"
        :to="link.url"
        class="main-nav__link"
        custom
      >
        <a
          :href="href"
          :class="[isExactActive && '_active', classLink]"
          @click="navigate"
          >{{ link.title }}</a
        >
      </NuxtLink>
    </li> -->
    <NuxtLink
      v-for="link in navList"
      :key="link.id"
      :to="link.url"
      tag="li"
      exact-active-class="_active"
      @click.native="handlerCloseMobileMenu"
    >
      <a :class="classLink">{{ link.title }}</a>
    </NuxtLink>
  </ul>
</template>

<script>
export default {
  props: {
    classList: {
      type: String,
      default: 'list',
    },
    classLink: {
      type: String,
      default: 'list__link',
    },
    navList: {
      type: Array,
      default: null,
    },
  },
  computed: {
    isMobile() {
      return this.windowWidth < 1025 || this.$device.isMobileOrTablet;
    },
  },
  methods: {
    isHashRouter(link) {
      const routeHash = this.$route.hash;

      if (!routeHash) return true;

      const urlHash = link.split(/#|\?/);
      return routeHash === `#${urlHash[1]}`;
    },
    handlerCloseMobileMenu() {
      if (!this.isMobile) return;

      this.$emit('closeMobileMenu');
    },
  },
};
</script>