import BaseApiClass from './base-api-class';
import { BlockContent, Partner, RawBlockContent } from '~/declarations/content';
import { AxiosResponse } from 'axios';
import { Results } from '~/declarations/shared';

interface RichTextRecord {
  id: number;
  name: string;
  text: string;
  number: number;
}

export default class Content extends BaseApiClass {
  /**
   * Получаем контент для определенного блока
   * @param {string} block
   * @returns {Promise<BlockContent>}
   */
  async getBlock(block: string): Promise<BlockContent> {
    const params = { block_name: block };
    try {
      const { data } = (await this.axios.get('/api/landing-page-content/blocks', {
        params,
      })) as AxiosResponse<RawBlockContent>;
      const content: BlockContent = {
        images: Object.fromEntries(data.images.map(({ slug, image }) => [slug, image])),
        titles: Object.fromEntries(data.titles.map(({ slug, text }) => [slug, text])),
        texts: Object.fromEntries(data.texts.map(({ slug, text }) => [slug, text])),
        buttons: Object.fromEntries(data.button_titles.map(({ slug, text }) => [slug, text])),
        menuItems: Object.fromEntries(data.menu_items.map(({ slug, text }) => [slug, text])),
      };
      return content;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  /**
   * Получаем список партнёров
   * @returns {Promise<Array<Partner>>}
   */
  async getPartners(): Promise<Array<Partner>> {
    try {
      const {
        data: { results: data },
      } = (await this.axios.get('/api/landing-page-content/partners')) as AxiosResponse<
        Results<Partner>
      >;
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getVolunteersInfo(): Promise<Array<string>> {
    try {
      const {
        data: { results: data },
      } = (await this.axios.get('/api/landing-page-content/volunteers')) as AxiosResponse<
        Results<RichTextRecord>
      >;
      return data.map(({ text }) => text);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getOrganizationsInfo(): Promise<Array<string>> {
    try {
      const {
        data: { results: data },
      } = (await this.axios.get('/api/landing-page-content/organizations')) as AxiosResponse<
        Results<RichTextRecord>
      >;
      return data.map(({ text }) => text);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}
