var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBody',{attrs:{"title":_vm.title},on:{"closeModal":_vm.closeModal}},[_c('div',{staticClass:"modal__content"},[(_vm.errorsRequest.length)?_c('div',{staticClass:"errors"},_vm._l((_vm.errorsRequest),function(error){return _c('p',{key:error},[_vm._v(_vm._s(error))])}),0):_vm._e(),_vm._v(" "),_c('ValidationObserver',{attrs:{"name":"registration","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-modal",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitRegistration)}}},[_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"phone","rules":{ required: true, phone: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validated = ref.validated;
var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"phone-reg"}},[_vm._v("\n            Номер телефона "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"},{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],staticClass:"input",class:{
              'input--error': validated && errors.length > 0,
            },attrs:{"id":"phone-reg","type":"text","autocomplete":"off","placeholder":"+7 (___) ___-__-__"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"name","rules":{ required: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validated = ref.validated;
            var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"name-reg"}},[_vm._v("\n            Имя "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input",class:{
              'input--error': validated && errors.length > 0,
            },attrs:{"id":"name-reg","type":"text","autocomplete":"off"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"lastName","rules":{ required: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validated = ref.validated;
            var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"lastName-reg"}},[_vm._v("\n            Фамилия "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],staticClass:"input",class:{
              'input--error': validated && errors.length > 0,
            },attrs:{"id":"lastName-reg","type":"text","autocomplete":"off"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}}),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"middleName","rules":{},"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validated = ref.validated;
            var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"middleName-reg"}},[_vm._v("\n            Отчество\n          ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.middleName),expression:"middleName"}],staticClass:"input",class:{
              'input--error': validated && errors.length > 0,
            },attrs:{"id":"middleName-reg","type":"text","autocomplete":"off"},domProps:{"value":(_vm.middleName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.middleName=$event.target.value}}}),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"phone","rules":{ required: true, email: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validated = ref.validated;
            var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"email-reg"}},[_vm._v("\n            Почта "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input",class:{
              'input--error': validated && errors.length > 0,
            },attrs:{"id":"email-reg","type":"text","autocomplete":"off"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"password","rules":{
            required: true,
            password: true,
          },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"password"}},[_vm._v("\n            Пароль "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('div',{staticClass:"input-with-icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass),expression:"pass"}],staticClass:"input",class:{ 'input--error': validated && errors.length > 0 },attrs:{"id":"password","type":"password","autocomplete":"off"},domProps:{"value":(_vm.pass)},on:{"focus":_vm.resetShowPassword,"input":function($event){if($event.target.composing){ return; }_vm.pass=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"icon-eye",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.toggleShowPassword.apply(null, arguments)}}},[_c('svg-icon',{attrs:{"name":"eye","width":"22","height":"16"}})],1)]),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"message"},[_vm._v("Пароль должен содержать и буквы, и цифры, не менее 8\n            символов")])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"secPassword","rules":{
            required: true,
            setPassword: ['@password'],
          },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"sec-password"}},[_vm._v("\n            Подтверждение пароля "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('div',{staticClass:"input-with-icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secPass),expression:"secPass"}],staticClass:"input",class:{ 'input--error': validated && errors.length > 0 },attrs:{"id":"sec-password","type":"password","autocomplete":"off"},domProps:{"value":(_vm.secPass)},on:{"focus":_vm.resetShowPassword,"input":function($event){if($event.target.composing){ return; }_vm.secPass=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"icon-eye",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleShowPassword.apply(null, arguments)}}},[_c('svg-icon',{attrs:{"name":"eye","width":"22","height":"16"}})],1)]),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-modal__confirm",attrs:{"name":"agreement","rules":"checkboxReq","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreement),expression:"agreement"}],attrs:{"id":"remember","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.agreement)?_vm._i(_vm.agreement,null)>-1:(_vm.agreement)},on:{"change":function($event){var $$a=_vm.agreement,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agreement=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agreement=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agreement=$$c}}}}),_vm._v(" "),_c('label',{staticClass:"label",attrs:{"for":"remember"}},[_vm._v("\n            Я прочитал и согласен с условиями\n            "),_c('a',{attrs:{"href":_vm.ROUTER_LINK.agreement,"target":"_blank"}},[_vm._v("Пользовательского соглашения\n            ")]),_vm._v("\n            , "),_c('a',{attrs:{"href":_vm.ROUTER_LINK.privacy,"target":"_blank"}},[_vm._v("Политикой конфиденциальности")]),_vm._v("\n            , и даю\n            "),_c('a',{attrs:{"href":_vm.ROUTER_LINK.consentToProcessing,"target":"_blank"}},[_vm._v("согласие")]),_vm._v(" на обработку персональных данных.\n          ")]),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn btn-green btn--arrow form-modal__button",attrs:{"type":"submit"}},[_c('span',[_vm._v("Регистрация")]),_vm._v(" "),_c('svg-icon',{attrs:{"name":"arrow","width":"6px","height":"10px","stroke":"#ffffff"}})],1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"modal__bottom"},[_c('p',[_vm._v("Есть аккаунт?")]),_vm._v(" "),_c('button',{staticClass:"create-link",on:{"click":_vm.handlerOpenModalLogin}},[_c('span',[_vm._v("Войти")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }