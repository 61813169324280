import { AxiosResponse } from 'axios';
import BaseApiClass from '~/api/base-api-class';
import { LoggedInUser } from '~/declarations/users';
import { SocialAuthType, SocialNetworkRedirect } from '~/declarations/shared';

// class UserDoesNotFit extends Error {
//
// }

export interface CheckResult {
  result: boolean;
  isExist: boolean;
  error: string;
}

export interface SocialAuthResponse {
  token: string | null;
  phone_confirm_required: boolean;
  phone_number: string | null;
  social_auth_access_token: string;
}

export default class Auth extends BaseApiClass {
  /**
   * Получаем ссылку для авторизации через социальные сети
   * @param type
   */
  async getSocialLink(type: SocialAuthType): Promise<{ url: string }> {
    const { data } = (await this.axios.get('/api/get-social-link/', {
      params: { type },
    })) as AxiosResponse<{ url: string }>;
    return data;
  }

  /**
   *
   * @param {Required<SocialNetworkRedirect> & {provider: string}} data
   * @returns {Promise<SocialAuthResponse>}
   */
  async sendCodeFromSocialNetwork(data: Required<SocialNetworkRedirect> & { provider: string }) {
    const { data: response } = (await this.axios.post(
      '/api/social-auth/login/social/token/',
      data
    )) as AxiosResponse<SocialAuthResponse>;
    return response;
  }

  /**
   *
   * @param {string} phone
   * @returns {Promise<CheckResult>}
   */
  async checkPhone(phone: string) {
    const { data } = (await this.axios.post('/api/valid-phone/', {
      phone,
    })) as AxiosResponse<CheckResult>;
    return data;
  }

  async sendCode(requestData: { phone: string; email?: string }) {
    const { data } = (await this.axios.post('/api/send-code/', requestData)) as AxiosResponse<void>;
    return data;
  }

  async verifyCode(code: string, socialToken: string) {
    const {
      data: { token },
    } = (await this.axios.post('/api/verify-code/', {
      code,
      social_auth_access_token: socialToken,
    })) as AxiosResponse<{ token }>;
    return token;
  }
};
