import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"bottom-right","theme":"bubble","duration":3000,"iconPack":"material","action":[{"icon":"close","onClick":(e, toastObject) => {
        toastObject.goAway(0);
      }}]})

const globals = undefined
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
