import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { FAVORITE_TYPES } from '@/constants/constList';
import { Organization } from '~/declarations/organizations';
import { StateInterface } from '~/store/index';

export interface UserSubscribeInterface {
  dispatchSubscribe: null | Date|number;
  maxTimeDispatch: number;
  subscribeOrganization: Record<Organization['id'], Organization>;
}

export const state = (): UserSubscribeInterface => ({
  dispatchSubscribe: null,
  maxTimeDispatch: 1, // минуты
  subscribeOrganization: {},
});

export const mutations: MutationTree<UserSubscribeInterface> = {
  setType(state, { type, data }: {type: string, data: Array<Organization>}) {
    const subscribe = data.reduce((acc: UserSubscribeInterface['subscribeOrganization'], el) => {
      acc[el.id] = el;
      return acc;
    }, {});
    if (type === 'organization') {
      // @ts-ignore
      this._vm.$set(state, 'subscribeOrganization', subscribe);
    }
  },
  setDateDispatch(state) {
    state.dispatchSubscribe = new Date();
  },

  addNew(state, { type, newSub }) {
    if (type === 'organization') {
      const subscribe = Object.assign({}, state.subscribeOrganization, {
        [newSub.id]: newSub,
      });
      // @ts-ignore
      this._vm.$set(state, 'subscribeOrganization', subscribe);
    }
  },
  removeForID(state, { type, id }) {
    if (type === 'organization') {
      const subscribe = Object.assign({}, state.subscribeOrganization);
      delete subscribe[id];
      // @ts-ignore
      this._vm.$set(state, 'subscribeOrganization', subscribe);
    }
  },
  clearAll(state) {
    state.subscribeOrganization = {};
    state.dispatchSubscribe = null;
  },
};

export const actions: ActionTree<UserSubscribeInterface, StateInterface> = {
  async getSubscribe({ state, commit }) {
    const dateDispatch = +(new Date()) - state.maxTimeDispatch * 6000;
    // WTF: Что за херня тут творится
    // @ts-ignore
    if (!state.dispatchSubscribe && state.dispatchSubscribe < dateDispatch) {
      try {
        const { data: resEvent } = await this.$axios.get(
          '/api/users-organization-favourites-by-user/'
        );
        commit('setType', {
          type: FAVORITE_TYPES.organizations,
          data: resEvent.results,
        });
        commit('setDateDispatch');
      } catch (e) {
        console.log(e);
      }
    }
  },

  async addSubscribe({ commit }, { type, element }) {
    commit('addNew', { type, newSub: element });
    try {
      await this.$axios.post(
        `/api/add-user-organization-favourites/${element.id}/`
      );
      return true;
    } catch (e: any) {
      if (e.response.status === 401) {
        // @ts-ignore
        this.$toast.show('Авторизуйтесь для добавление в Подписки!');
      }
      commit('removeForID', { type, id: element.id });
      return false;
    }
  },
  async removeSubscribe({ commit }, { type, element }) {
    commit('removeForID', { type, id: element.id });
    try {
      await this.$axios.post(
        `/api/delete-user-organization-favourites/${element.id}/`
      );
      return true;
    } catch (e: any) {
      console.log(e);
      console.log(e.messagge);
      commit('addNew', { type, newSub: element });
      return false;
    }
  },
};

export const getters: GetterTree<UserSubscribeInterface, StateInterface> = {
  isSubscribe: (state) => {
    return (type: string, id: number) => {
      if (type === 'organization') {
        const subscribe = state.subscribeOrganization;
        const element = subscribe[id];
        if (element) return true;
        return false;
      }
      return false;
    };
  },
};
