import { AxiosInstance } from 'axios';
import BaseApiClass from '~/api/base-api-class';
import { User } from '~/declarations/users';
import FileDownloader from '~/constants/file-downloader';

export class Shared extends BaseApiClass {
  async getUser(id: number): Promise<User> {
    const { data } = await this.axios.get(`/api/users/${id}/`);
    return data;
  }

  async downloadFile(url: string, filename: string): Promise<void> {
    FileDownloader.saveFileFromResponse(await this.axios.get(url, { responseType: 'blob' }), filename);
  }
}
