


































import ModalLogin from '@/components/Modals/ModalContent/ModalLogin.vue';
import ModalPassReset from '@/components/Modals/ModalContent/ModalPassReset.vue';
import ModalCodeLogin from '@/components/Modals/ModalContent/ModalCodeLogin.vue';
import ModalNewPass from '@/components/Modals/ModalContent/ModalNewPass.vue';
import ModalSuccess from '@/components/Modals/ModalContent/ModalSuccess.vue';
import ModalRegister from '@/components/Modals/ModalContent/ModalRegister.vue';
import ModalCodeRegister from '@/components/Modals/ModalContent/ModalCodeRegister.vue';
import ModalPhoneConfirm from '@/components/Modals/ModalContent/ModalPhoneConfirm.vue';
import ModalPhoneConfirmCode from '@/components/Modals/ModalContent/ModalPhoneConfirmCode.vue';

import { AUTH_COMPONENTS } from '@/constants/constList';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { SocialAuthType } from '~/declarations/shared';

export const AllowedSocialNetworks: Array<SocialAuthType> = ['VK', 'google', 'odnoklassniki'];


@Component({
  name: 'ModalAuth',
  components: {
    ModalLogin,
    ModalPassReset,
    ModalCodeLogin,
    ModalNewPass,
    ModalSuccess,
    ModalRegister,
    ModalCodeRegister,
    ModalPhoneConfirm,
    ModalPhoneConfirmCode,
  },
})
export default class ModalAuth extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  paramsModal!: any;

  heightContainer = 'auto';

  currentModal = AUTH_COMPONENTS.login;

  params = {
    allowedSocials: AllowedSocialNetworks,
  };

  $refs!: {
    modalContainer: HTMLElement;
  };

  get isMobile() {
    return this.windowWidth < 769 || this.$device.isMobile;
  }

  closeModal() {
    this.$emit('closeModal');
  }

  openModal(value) {
    if (value.modal) this.currentModal = value.modal;
    if (value.data) this.params = value.data;
  }

  // animation methods
  enter(element) {
    const container = this.$refs.modalContainer;
    const width = getComputedStyle(element).width;

    container.style.height = this.heightContainer;

    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';

    const height = getComputedStyle(element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;

    if (!this.isMobile) {
      container.style.overflow = 'hidden';
      this.heightContainer = height;
      requestAnimationFrame(() => {
        container.style.height = height;
      });
    }
  }

  afterEnter(element) {
    const container = this.$refs.modalContainer;
    container.style.overflow = 'none';
    element.style.height = null;
    this.$refs.modalContainer.style.height = 'none';
  }

  leave(element) {
    // element.style.width = '100%';
    // element.style.position = 'absolute';
    // element.style.visibility = 'hidden';
    // element.style.height = 'auto';
  }

  mounted() {
    this.heightContainer = getComputedStyle(this.$refs.modalContainer).height;
    if (Object.keys(this.paramsModal).length) {
      this.openModal(this.paramsModal);
    }
  }
};
