<template>
  <ModalBody
    :title="title"
    :is-backbutton="true"
    @goBack="goBack"
    @closeModal="closeModal"
  >
    <div class="modal__content">
      <div v-if="errorsRequest.length" class="errors">
        <p v-for="error in errorsRequest" :key="error">{{ error }}</p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }" name="phoneReset" slim>
        <form
          class="form-modal"
          @submit.prevent="handleSubmit(handlerOpenModalCodeLogin)"
        >
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="phoneReset"
            :rules="{ required: true, phone: true }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="phone-r">
              Номер телефона <span class="input-req"> *</span>
            </label>
            <input
              id="phone-r"
              v-model="phone"
              v-mask="phoneMask"
              type="text"
              class="input"
              :class="{ 'input--error': validated && errors.length > 0 }"
              autocomplete="off"
              placeholder="+7 (___) ___-__-__"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
              errors[0]
            }}</span>
          </ValidationProvider>
          <button
            class="btn btn-green btn--arrow form-modal__button"
            type="submit"
          >
            <span>Продолжить</span>
            <svg-icon
              name="arrow"
              width="6px"
              height="10px"
              stroke="#ffffff"
            ></svg-icon>
          </button>
        </form>
      </ValidationObserver>
    </div>
  </ModalBody>
</template>

<script>
import ModalBody from '@/components/Modals/ModalBody/ModalBody.vue';
import { phoneMask, unmask } from '@/constants/constFormated';
import { AUTH_COMPONENTS } from '@/constants/constList';

export default {
  name: 'ModalPassReset',
  components: {
    ModalBody,
  },
  data() {
    return {
      title: 'Забыли пароль?',
      phone: '',
      phoneMask,
      errorsRequest: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    goBack() {
      this.$emit('openModalNext', { modal: AUTH_COMPONENTS.login });
    },
    async handlerOpenModalCodeLogin() {
      const data = {
        phone: unmask(this.phone, phoneMask),
      };
      try {
        const phoneValid = await this.$axios
          .post('/api/valid-phone/', data)
          .then((res) => {
            return res.data.isExists;
          });

        if (!phoneValid) {
          throw new Error('Такого пользователя не существет!');
        }

        await this.$api.auth.sendCode(data);
        this.$emit('openModalNext', { modal: AUTH_COMPONENTS.code, data });
      } catch (e) {
        console.log(e);
        this.errorsRequest = [e.message];
      }
    },
  },
};
</script>
