import {
  Event,
  EventFinishForm,
  EventMember,
  EventMemberRequirements,
  EventMemberScores,
  EventReportFile,
} from '~/declarations/events';
import { AxiosPromise, AxiosResponse } from 'axios';
import BaseApiClass from '~/api/base-api-class';
import { FileBlock, Results, WithManageButton } from '~/declarations/shared';
import { IRatingReview } from '~/components/ui/RatingReview.vue';
import { event } from 'quasar';

export default class Events extends BaseApiClass {
  /**
   * Получаем конкретное мероприятие по id
   * @param id
   */
  async get(id: number): Promise<Event> {
    const { data } = (await this.axios.get(`/api/events/${id}/`)) as AxiosResponse<Event>;
    return data;
  }

  /**
   * Получаем участников мероприятия
   * @param id
   */
  async getMembers(id: number): Promise<Array<EventMember>> {
    const {
      data: { results },
    } = (await this.axios.get(`/api/event_members/${id}/`)) as AxiosResponse<Results<EventMember>>;
    return results;
  }

  /**
   * Завершаем мероприятие
   * @param id
   * @param data
   */
  async finish(id: number) {
    await this.axios.post(`/api/events/${id}/revision-end/`);
  }

  /**
   * Сохраняем отчет о мероприятии
   * @param eventId
   * @param data
   */
  async saveReport(eventId: number, data: FormData) {
    await this.axios.put(`/api/feedback/event/${eventId}/owner/`, data);
  }

  /**
   * Получаем отчет о мероприятии
   * @param eventId
   * @param data
   */
  async getReport(eventId: number) {
    const { data } = (await this.axios.get(
      `/api/feedback/event/${eventId}/owner/`,
    )) as AxiosResponse<EventFinishForm>;
    return data;
  }

  async saveReportFile(eventId: number, data: FormData) {
    await this.axios.post(`/api/events/${eventId}/files/`, data);
  }

  /**
   * Получаем все файлы отчета о мероприятии.
   * @param {number} eventId
   * @returns {Promise<Array<EventReportFile>>}
   */
  async getReportFiles(eventId: number) {
    const [
      {
        data: { results: images },
      },
      {
        data: { results: documents },
      },
    ] = await Promise.all([
      this.axios.get(`/api/events/${eventId}/files/`, {
        params: { type: 'feedback_media' },
      }) as AxiosPromise<Results<EventReportFile>>,
      this.axios.get(`/api/events/${eventId}/files/`, {
        params: { type: 'feedback_document' },
      }) as AxiosPromise<Results<EventReportFile>>,
    ]);
    return [...images, ...documents];
  }

  /**
   * Получаем файлы мероприятия типа event_files.
   * @param {number} eventId
   * @param type
   * @returns {Promise<Array<EventReportFile>>}
   */
  async getFilesWithType(eventId: number, type: string) {
    const {
      data: { results },
    } = (await this.axios.get(`/api/events/${eventId}/files/`, {
      params: { type: `${type}` },
    })) as AxiosResponse<Results<FileBlock>>;
    return results;
  }

  async deleteReportFile(fileId: number) {
    await this.axios.delete(`/api/events/files/${fileId}`);
  }

  /**
   * Отменяем мероприятие
   * @param {number} id
   * @returns {Promise<void>}
   */
  async cancel(id: number): Promise<void> {
    await this.axios.post(`/api/events/${id}/delete/`);
  }

  /**
   * Восстанавливаем мероприятие
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  async restore(id: number): Promise<void> {
    await this.axios.post(`/api/events/${id}/restore/`);
  }

  /**
   * Отправка мероприятия на модерацию
   * @param {number} id
   * @returns {Promise<void>}
   */
  async moderate(id: number): Promise<void> {
    await this.axios.post(`/api/events/${id}/revision-new/`);
  }

  /**
   *  Удаляем волонтера из мероприятия
   * @param {number} memberId
   * @returns {Promise<any>}
   */
  async removeMember(memberId: number): Promise<any> {
    const { data } = await this.axios.delete(`/api/event_member/${memberId}/`);
    return data;
  }

  async getEventMemberRequirements(eventId: number): Promise<Array<EventMemberRequirements>> {
    const {
      data: { results },
    } = await this.axios.get(`/api/event_member_requirements/${eventId}/`);
    return results;
  }

  /**
   * Получаем отзыв о мероприятии
   * @param id
   */
  async getFeedback(id: string): Promise<IRatingReview> {
    const { data } = (await this.axios.get(
      `/api/feedback/event/${id}/member/`,
    )) as AxiosResponse<IRatingReview>;
    return data;
  }

  async getMemberScore(id: number): Promise<Array<EventMemberScores>> {
    const {
      data: { results },
    } = (await this.axios.get(`/api/member_eval_list/${id}/`)) as AxiosResponse<Results<EventMemberScores>>;
    return results;
  }

  async saveMemberScore(data: EventMemberScores, existing: boolean): Promise<void> {
    if (existing) {
      await this.axios.put(`/api/member_eval/`, data);
    } else {
      await this.axios.post(`/api/member_eval/`, data);
    }
  }

  /**
   * Отправляем отзыв о мероприятии
   * @param id
   * @param data
   */
  async sendFeedback(id: string, data: IRatingReview) {
    await this.axios.put(`/api/feedback/event/${id}/member/`, data);
  }

  /**
   * Запрашиваем черновики
   *
   * @returns {Promise<Array<Event>>}
   */
  public async getDrafts(): Promise<Array<WithManageButton<Event>>> {
    const {
      data: { results },
    } = await this.axios.get('/api/events_drafts_cuc/') as AxiosResponse<Results<Event>>;
    return results.map((event) => ({ ...event, buttons: { btnManage: true } }));
  }
};
