// для личного кабинета - созданые Мероприятия ( чтоб не делать запросы постаянно на сервер)

export interface CreatedEventsInterface {
  createdEvents: Array<unknown>,
  isSetProfileEnums: {
    status: boolean;
    date: string;
  }
};

export const state = (): CreatedEventsInterface => ({
  createdEvents: [],
  isSetProfileEnums: {
    status: false,
    date: '',
  },
});
