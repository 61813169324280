// import { cloneDeep } from 'lodash';
import { getRequestFormatDate, getRequestFormatTime, phoneMask, unmask } from '~/constants/constFormated';
import { KEY_COMMUNICATIONS } from '~/constants/constList';
import { ActionTree, MutationTree } from 'vuex';
import { StateInterface } from '~/store/index';
import { CommunicationType, CommunicationVisibility, CommunicationWay, FileBlock } from '@/declarations/shared';
import { DescriptionBlock, EventMain, VolunteerRequirements } from '~/declarations/events';

;

export interface EventCreatorInterface {
  main: Partial<EventMain & CommunicationWay & CommunicationVisibility>,
  descriptionBlocks: Array<DescriptionBlock>,
  deletedBlocks: Array<number>,
  submitBlocksDescription: Record<string, unknown>,
  fileBlocks: Array<FileBlock>,
  deletedFileBlocks: Array<number>,
  submitFileBlocks: Record<string, unknown>
  requirementsVolunteer: Record<string, any>,
  // requirementsVolunteer: Record<string, VolunteerRequirements | Record<string, never>>,
  deletedRequirementsVolunteer: Array<unknown>,
  submitRequirements: Record<string, any>,
  errorsRequest: Array<unknown>,
  isEditEvent: boolean,
  createEventId: null | number,
}


export const state = (): EventCreatorInterface => ({
  main: {},
  descriptionBlocks: [],
  fileBlocks: [],
  deletedFileBlocks: [],
  deletedBlocks: [],
  submitFileBlocks: {},
  submitBlocksDescription: {},
  requirementsVolunteer: {},
  deletedRequirementsVolunteer: [],
  submitRequirements: {},
  errorsRequest: [],
  isEditEvent: false,
  createEventId: null,
});

export const mutations: MutationTree<EventCreatorInterface> = {
  setMain(state, obj: { key: keyof EventMain, value: any }) {
    if (typeof obj.value === 'object') {
      if (Array.isArray(obj.value)) {
        state.main[obj.key] = [...obj.value];
      } else if (obj.value instanceof File) {
        state.main[obj.key] = obj.value;
      } else {
        state.main[obj.key] = JSON.parse(JSON.stringify(obj.value));
      }
    }

    state.main[obj.key] = obj.value;
  },

  setEventId(state, id: number) {
    state.createEventId = id;
  },

  setIsEditEvent(state, value: boolean) {
    state.isEditEvent = value;
  },

  setDescriptionBlocks(state, arr: EventCreatorInterface['descriptionBlocks']) {
    const newArr: EventCreatorInterface['descriptionBlocks'] = [];
    arr.forEach((el) => {
      newArr.push(Object.assign({}, el));
    });
    state.descriptionBlocks = [...newArr];
  },

  deleteDescriptionBlock(state, id) {
    state.deletedBlocks = [...state.deletedBlocks, id];
  },

  clearDeleteDescriptionBlock(state, id: number) {
    const index = state.deletedBlocks.findIndex((el) => el === id);
    if (index >= 0) {
      // @ts-ignore
      const arr = [...this.deletedBlocks];

      arr.splice(index, 1);
      state.deletedBlocks = [...arr];
    }
  },

  setFileBlocks(state, arr: EventCreatorInterface['fileBlocks']) {
    const newArr: EventCreatorInterface['fileBlocks'] = [];
    arr.forEach((el) => {
      newArr.push(Object.assign({}, el));
    });
    state.fileBlocks = [...newArr];
  },

  deleteFileBlock(state, id) {
    state.deletedFileBlocks = [...state.deletedFileBlocks, id];
  },

  clearDeletedFileBlock(state, id: number) {
    const index = state.deletedFileBlocks.findIndex((el) => el === id);
    if (index >= 0) {
      // @ts-ignore
      const arr = [...state.deletedFileBlocks];

      arr.splice(index, 1);
      state.deletedFileBlocks = [...arr];
    }
  },

  setSubmitFileBlocks(state, object) {
    const id = object.id;
    state.submitFileBlocks[id] = Object.assign({}, object);
  },

  clearSubmitFileBlocks(state: EventCreatorInterface): void {
    state.submitFileBlocks = {};
  },

  setSubmitBlocksDescription(state, object) {
    const id = object.id;
    state.submitBlocksDescription[id] = Object.assign({}, object);
  },

  clearSubmitBlocksDescription(state: EventCreatorInterface): void {
    state.submitBlocksDescription = {};
  },

  setRequirementsVolunteer(state, object: any) {
    state.requirementsVolunteer = {};
    Object.keys(object).forEach((element) => {
      state.requirementsVolunteer[element] = {};
      for (const key in object[element]) {
        const value = object[element][key];
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            state.requirementsVolunteer[element][key] = [...value];
          } else {
            state.requirementsVolunteer[element][key] = Object.assign(
              {},
              value,
            );
          }
        }
        state.requirementsVolunteer[element][key] = value;
      }
    });
  },

  deleteRequirement(state, id) {
    const res = state.deletedRequirementsVolunteer.find((el) => el === id);
    if (!res) {
      state.deletedRequirementsVolunteer = [
        ...state.deletedRequirementsVolunteer,
        id,
      ];
    }
  },

  clearDeleteRequirement(state, id) {
    const index = state.deletedRequirementsVolunteer.findIndex(
      (el) => el === id,
    );
    if (index >= 0) {
      const arr = state.deletedRequirementsVolunteer;

      arr.splice(index, 1);
      state.deletedRequirementsVolunteer = [...arr];
    }
  },

  setSubmitRequirements(state, object) {
    const id = object.id;
    state.submitRequirements[id] = Object.assign({}, object);
  },

  clearSubmitRequirements(state) {
    state.submitRequirements = {};
  },

  clear(state) {
    state.main = {};
    state.descriptionBlocks = [];
    state.deletedBlocks = [];
    state.submitBlocksDescription = {};
    state.requirementsVolunteer = {};
    state.deletedRequirementsVolunteer = [];
    state.submitRequirements = {};
    state.fileBlocks = [];
    state.deletedFileBlocks = [];
    state.submitFileBlocks = {};
    state.errorsRequest = [];
    state.createEventId = null;
  },
};

export const actions: ActionTree<EventCreatorInterface, StateInterface> = {
  setDefaultEventCreator({ commit }) {
    commit('clear');
  },

  async submitEvent({ dispatch }, { draft }) {
    try {
      await dispatch('sendCreateEvent', { draft });
      await dispatch('sendDescriptionBlocks');
      await dispatch('sendRequirements');
      await dispatch('sendFiles');
      return true;
    } catch (e) {
      // определить где была ошибка и выдать определённую ошибку!
      console.log(e);
      throw e;
    }
  },
  async sendCreateEvent({ state, dispatch, commit }, { draft }) {
    // создание мероприятия из данных в сторе!!
    console.log(draft);
    try {
      await commit('setIsEditEvent', true);
      const eventFormData = await dispatch('getMainEventFormData');
      const url: string = draft ? '/api/events_drafts/' : '/api/events/';
      if (!state.createEventId) {
        const result = await this.$axios.post(url, eventFormData);
        const idEvent = result.data.id;
        await commit('setEventId', idEvent);
      } else {
        await this.$axios.patch(`${url}${state.createEventId}/`, eventFormData);
      }
      await commit('setIsEditEvent', false);
      // нужно подумать что делать после их создания и изменения? может запрос на обновление данных и т.п. если будет ошибка в волонтерах
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async sendDescriptionBlocks({ state, commit, dispatch }) {
    if (!state.createEventId) {
      throw new Error('Мероприятие не удалось создать');
    }
    try {
      await commit('setIsEditEvent', true);
      for (let i = 0; i < state.descriptionBlocks.length; i++) {
        const el = state.descriptionBlocks[i];
        const blocksFormData = await dispatch(
          'getDescriptionBlockFormData',
          el,
        );

        if (typeof el.id === 'number') {
          const result = await this.$axios.patch(
            `/api/info_blocks/${el.id}/`,
            blocksFormData,
          );
          console.log(result);
        } else {
          const result = await this.$axios.post(
            '/api/info_blocks/',
            blocksFormData,
          );
          console.log(result);
          commit('setSubmitBlocksDescription', el);
        }
      }

      for (let i = 0; i < state.deletedBlocks.length; i++) {
        console.log({ deletedBlocks: state.deletedBlocks[i] });
        const result = await this.$axios.delete(
          `/api/info_blocks/${state.deletedBlocks[i]}/`,
        );
        console.log(result);
        commit('clearDeleteDescriptionBlock', state.deletedBlocks[i]);
      }
      await commit('setIsEditEvent', false);
    } catch (e) {
      // обработка Требований которые загрузились!!!
      // нужно их удалить со стора и бросить запрос на получение загруженных Требований Созбанного события на сервер
      const objectThrow = {} as Record<string, any>;
      objectThrow.error = e;
      objectThrow.type = 'descriptionBlocks';
      throw objectThrow;
    }
  },
  async sendFiles({ state, commit, dispatch }) {
    if (!state.createEventId) {
      throw new Error('Мероприятие не удалось создать');
    }
    try {
      await commit('setIsEditEvent', true);
      for (let i = 0; i < state.fileBlocks.length; i++) {
        const el = state.fileBlocks[i];
        if (!el.file) {
          continue;
        }
        const fileBlocksFormData = await dispatch(
          'getFileBlockFormData',
          {
            ...el, ...{ type: 'event_files' },
          },
        );

        if (typeof el.id === 'string') {
          const result = await this.$axios.post(
            `/api/events/${state.createEventId}/files/`,
            fileBlocksFormData,
          );
          console.log(result);
        }
        commit('setSubmitFileBlocks', el);
      }
      while (state.deletedFileBlocks.length > 0) {
        console.log({ deletedFileBlocks: state.deletedFileBlocks[0] });
        const result = await this.$axios.delete(
          `/api/events/files/${state.deletedFileBlocks[0]}`,
        );
        console.log(result);
        commit('clearDeletedFileBlock', state.deletedFileBlocks[0]);
      }
      await commit('setIsEditEvent', false);
    } catch (e) {
      // обработка Требований которые загрузились!!!
      // нужно их удалить со стора и бросить запрос на получение загруженных Требований Созбанного события на сервер
      const objectThrow = {} as Record<string, any>;
      objectThrow.error = e;
      objectThrow.type = 'files';
      throw objectThrow;
    }
  },
  async sendRequirements({ state, commit, dispatch }) {
    if (!state.createEventId) {
      throw new Error('Мероприятие не удалось создать');
    }
    try {
      await commit('setIsEditEvent', true);
      for (const key in state.requirementsVolunteer) {
        const el = state.requirementsVolunteer[key];
        const requrementFormData = await dispatch('getRequirementFormData', el);
        if (typeof el.id === 'number') {
          const result = await this.$axios.patch(
            `/api/event_member_requirement/${el.id}/`,
            requrementFormData,
          );
          console.log({ [el.id]: result });
        } else {
          const result = await this.$axios.post(
            `/api/event_member_requirements/${state.createEventId}/`,
            requrementFormData,
          );
          commit('setSubmitRequirements', el);
          console.log({ [el.id]: result });
        }
      }

      for (let i = 0; i < state.deletedRequirementsVolunteer.length; i++) {
        const result = await this.$axios.delete(
          `/api/event_member_requirement/${state.deletedRequirementsVolunteer[i]}/`,
        );
        console.log(result);
        commit('clearDeleteRequirement', state.deletedRequirementsVolunteer[i]);
      }
      await commit('setIsEditEvent', false);
    } catch (e) {
      // обработка блоков которые загрузились!!!
      // нужно их удалить со стора и бросить запрос на получение загруженных блоков на сервер
      const objectThrow = {} as Record<string, any>;
      objectThrow.error = e;
      objectThrow.type = 'requirements';
      throw objectThrow;
    }
  },

  getMainEventFormData({ state, rootState }): FormData {
    // const state.main = state.main;
    const formData = new FormData();
    if (state.main.eventName) {
      formData.append('name', state.main.eventName);
    }
    if (state.main.participationFormat) {
      formData.append('participation_format', state.main.participationFormat);
    }

    if (state.main.anounceFile instanceof File) {
      formData.append('image', state.main.anounceFile);
    }
    // тип мероприятия
    if (state.main.mainEventType) {
      formData.append('main_type', state.main.mainEventType);
    } else {
      const mainType = Object.keys(state.main.eventTypes || {}).length
        ? Object.keys(state.main.eventTypes)[0]
        : '';
      if (mainType) {
        formData.append('main_type', mainType);
      }
    }

    if (state.main.eventTypes) {
      Object.keys(state.main.eventTypes).forEach((id) => {
        formData.append('event_type', id);
      });
    }

    // youtube_links
    if (state.main.youtubeLinks) {
      const linkObject = Object.fromEntries(state.main.youtubeLinks.map(({ url }, index) => [index, url]));
      formData.append('youtube_links', JSON.stringify(linkObject));
    }

    // start_date
    formData.append(
      'start_date',
      getRequestFormatDate(state.main.startEventDate || ''),
    );
    // start_time
    formData.append(
      'start_time',
      getRequestFormatTime(state.main.startEventTime || ''),
    );
    // end_date
    formData.append('end_date', getRequestFormatDate(state.main.endEventDate || ''));
    // end_time
    formData.append('end_time', getRequestFormatTime(state.main.endEventTime || ''));
    // recruitment_end_date
    formData.append(
      'recruitment_end_date',
      getRequestFormatDate(state.main.endVolunteerDate || ''),
    );

    // venue
    formData.append('venue', state.main.mapAddress || '');
    formData.append('city', state.main.mapCity || '');
    //  'lat',
    const { mapCoords: [lat, long] = ['', ''] } = state.main;
    formData.append('lat', lat);
    //  'lng',
    formData.append('lng', long);

    if (rootState.organization.isOrganization) {
      if (state.main.coordinator) {
        formData.append('coordinator', `${state.main.coordinator.id}`);
      }
      formData.append('organisation', `${rootState.organization.activeOrganization}`);
      formData.append('owner_type', 'organization');
    }
    // owner
    // eventFormData.append('owner', 'user' + this.$auth.user.id);
    // communications
    KEY_COMMUNICATIONS.forEach((key: CommunicationType) => {
      // @ts-ignore
      const value = state.main[`${key}Communication`] as string ?? '';
      // @ts-ignore
      const visible = state.main[`${key}Visible`] as boolean ?? false;
      console.log(key, value, visible);
      formData.append(`visible_${key}`, `${visible}`);
      if (
        key === 'phone' ||
        key === 'whatsapp' ||
        key === 'viber' ||
        key === 'telegramm'
      ) {
        formData.append(`communication_${key}`, unmask(value, phoneMask));
      } else {
        formData.append(`communication_${key}`, value);
      }
    });

    return formData;
  },

  getDescriptionBlockFormData({ state }, blocks) {
    const formDescription = new FormData();
    const eventId = state.createEventId;
    if (typeof blocks.id === 'number') {
      formDescription.append('event', `${eventId}`);
      formDescription.append('type', blocks.type);
      if (blocks.type === 'text') {
        formDescription.append('text', blocks.text);
      } else if (blocks.type === 'image') {
        if (blocks.file instanceof File) {
          formDescription.append('image', blocks.file);
        } else if (!blocks.image) {
          formDescription.append('image', '');
        }
      }
    } else {
      formDescription.append('event', `${eventId}`);
      formDescription.append('type', blocks.type);
      if (blocks.type === 'text') {
        formDescription.append('text', blocks.text);
      } else if (blocks.file instanceof File) {
        formDescription.append('image', blocks.file);
      }
    }
    return formDescription;
  },
  getFileBlockFormData({ state }, file) {
    const formData = new FormData();
    const keys = Object.keys(file);
    console.log();
    for (const key of keys) {
      let value: any;
      if (typeof file[key] === 'string') {
        value = file[key] as string;
      } else if (typeof file[key] === 'number') {
        value = (file[key] as number).toString();
      } else if (typeof file[key] === 'boolean') {
        value = (file[key] as boolean).toString();
      } else if (file[key] instanceof File || file[key] instanceof FileList) {
        value = file[key];
      }
      formData.append(key, value);
    }
    return formData;
  },

  getRequirementFormData({ state }, req: VolunteerRequirements) {
    const formRequirement = new FormData();
    formRequirement.append('name', req.typeVolunteer);
    formRequirement.append('event', `${state.createEventId}`);

    const jobTypes: Array<string> = [];
    Object.keys(req.jobTypes).forEach((key) => {
      // @ts-ignore
      if (req.jobTypes[key]) {
        jobTypes.push(key);
      }
    });
    if (jobTypes.length) {
      jobTypes.forEach((el) => {
        formRequirement.append('job_type', el);
      });
    }

    const skillTypes: Array<string> = [];
    Object.keys(req.skillTypes).forEach((key) => {
      // @ts-ignore
      if (req.skillTypes[key]) {
        skillTypes.push(key);
      }
    });

    if (skillTypes.length) {
      skillTypes.forEach((el) => {
        formRequirement.append('skill_type', el);
      });
    }

    formRequirement.append('min_age', req.minAge);
    formRequirement.append('max_age', req.maxAge);
    formRequirement.append('maximum_attende', req.count);
    if (req.sex !== null) {
      formRequirement.append('sex', req.sex);
    }

    // Образование
    if (req.education && req.education.id !== 0) {
      formRequirement.append('education', req.education.id);
    }
    // else {
    // formRequirement.append('education', 0);
    // }

    // Гражданство
    if (req.nationality) {
      formRequirement.append('nationality', req.nationality.id);
    }

    // Языки
    const languages: Array<string> = [];
    Object.keys(req.languages).forEach((key) => {
      if (req.languages[key]) {
        languages.push(req.languages[key].id);
      }
    });

    if (languages.length) {
      languages.forEach((el) => {
        formRequirement.append('language', el);
      });
    } else {
      formRequirement.append('language', 'empty');
    }

    //  Ограничения по здоровью
    if (req.healthRestriction === '0') {
      formRequirement.append('health_restrictions', 'false');
    } else if (req.healthRestriction === '1') {
      formRequirement.append('health_restrictions', 'true');
    }

    // группа крови
    const bloodTypes: Array<string> = [];
    Object.keys(req.bloodType).forEach((key) => {
      if (req.bloodType[key]) {
        bloodTypes.push(key);
      }
    });

    if (bloodTypes.length) {
      bloodTypes.forEach((el) => {
        formRequirement.append('blood_type', el);
      });
    }

    // Частота занятий спортом
    if (req.sportTraining) {
      formRequirement.append('sports_training', req.sportTraining);
    }

    // Наличие автомобиля
    formRequirement.append('car_presence', `${!!req.isCar}`);

    // Поощрение от организатора
    const bonuses: Array<string> = [];
    Object.keys(req.bonuses).forEach((key) => {
      if (req.bonuses[key]) {
        bonuses.push(key);
      }
    });

    if (bonuses.length) {
      bonuses.forEach((el) => {
        formRequirement.append('organisation_incentives', el);
      });
    }

    // Специальное требование
    formRequirement.append('special_requirements', req.special_requirements);

    return formRequirement;
  },
};
