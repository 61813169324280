<template>
  <ModalBody :title="title" @closeModal="closeModal">
    <div class="modal__content">
      <form class="form">
        <p class="form__text">Ваш пароль успешно изменён.</p>
        <button
          v-show="!$auth.loggedIn"
          class="btn btn-green btn--arrow form__button"
          @click.prevent="handlerOpenModalLogin"
        >
          <span>Войти с новым паролем</span>
          <svg-icon
            name="arrow"
            width="6px"
            height="10px"
            stroke="#ffffff"
          ></svg-icon>
        </button>
      </form>
    </div>
  </ModalBody>
</template>

<script>
import ModalBody from '@/components/Modals/ModalBody/ModalBody.vue';
import { AUTH_COMPONENTS } from '@/constants/constList';

export default {
  name: 'ModalSuccess',
  components: {
    ModalBody,
  },
  data() {
    return {
      title: 'Смена пароля',
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    handlerOpenModalLogin() {
      this.$emit('openModalNext', { modal: AUTH_COMPONENTS.login });
    },
  },
};
</script>
