import Vue from 'vue';
import YmapPlugin from 'vue-yandex-maps';
const apiKey = process.env.apiYndx;
const settings = {
  apiKey,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

Vue.use(YmapPlugin, settings);
