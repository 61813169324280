import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';

// Import Swiper and modules
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper/core';

// Install modules
SwiperCore.use([Navigation, Pagination, Scrollbar]);

Vue.use(VueAwesomeSwiper /* { default global options } */);
