import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import isObject from 'lodash/isObject';
import { AxiosError } from 'axios';
import { isFunction } from 'lodash';
import { HTTP_CODE_ERROR } from '@/constants/http-codes';

type MakeRequestOptions = {
  startLoading?: CallableFunction;
  finishLoading?: CallableFunction;
};

const defaultMakeRequestOptions: MakeRequestOptions = {};

/**
 * Проверяем является ли исключение ошибкой axios
 * @param error
 * @returns
 */
export const ifAxiosError = (error: unknown): boolean => {
  if (isObject(error) && typeof (error as Record<string, unknown>)?.request !== 'undefined') {
    return true;
  }

  return false;
};

@Component
export default class BackendHelpers extends Vue {
  backendLoading: boolean = false;

  async makeRequest<T>(
    request: () => Promise<T>,
    options: Partial<MakeRequestOptions> = {},
  ): Promise<T> {
    const o: MakeRequestOptions = {
      ...defaultMakeRequestOptions,
      ...options,
    };
    try {
      if (o.startLoading && isFunction(o.startLoading)) {
        o.startLoading();
      } else {
        this.backendLoading = true;
      }
      return await request();
    } catch (error: unknown) {
      if (ifAxiosError(error) && (error as AxiosError).response?.data?.detail) {
        this.$toast.error((error as AxiosError).response?.data?.detail);
      } else if (ifAxiosError(error) && (error as AxiosError).response?.data?.non_field_errors) {
        (error as AxiosError).response?.data?.non_field_errors.forEach((message) => {
          this.$toast.error(message);
        })
      } else if (ifAxiosError(error) && (error as AxiosError).response?.data?.result) {
        this.$toast.error((error as AxiosError).response?.data?.error);
      } else {
        this.$toast.error('Что то пошло не так. Попробуйте позже');
      }
      throw error;
    } finally {
      if (o.finishLoading && isFunction(o.finishLoading)) {
        o.finishLoading();
      } else {
        this.backendLoading = false;
      }
    }
  }

}
