import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';

class IsMobile extends Vue {
  windowWidth: number = process.client ? window.innerWidth : 0;

  mounted() {
    if (process.client) {
      window.addEventListener('resize', this.onResize);
    }
  }

  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }

  toastError(e: unknown): void {
    if (e instanceof Error) {
      if (process.client) {
        this.$toast.error(e.message);
      } else {
        console.log(e);
      }
    }
  }
}

Vue.mixin(Component(IsMobile));
