// Функция блокировки фона при открытии модального окна/мобильного меню и т.д.
let timerBodyClass: any = null;
export const addRemoveBodyClass = (lock) => {
  // if (!header || !body) return;

  const body = document.querySelector('body') as HTMLBodyElement;
  const header = document.querySelector('header') as HTMLElement;
  const delay = 100;
  const bodyLockRemove = (delay) => {
    clearTimeout(timerBodyClass);
    if (!lock) {
      timerBodyClass = setTimeout(() => {
        body.style.paddingRight = '0px';
        header.style.paddingRight = '0px';
        body.classList.remove('_lock');
      }, delay);

      lock = true;
      timerBodyClass = setTimeout(function () {
        lock = false;
      }, delay);
    }
  };
  const bodyLockAdd = (delay) => {
    clearTimeout(timerBodyClass);
    if (lock) {
      const scrollHeight = window.innerWidth - body.offsetWidth + 'px';
      body.style.paddingRight = scrollHeight;
      header.style.paddingRight = scrollHeight;

      body.classList.add('_lock');

      lock = true;
      timerBodyClass = setTimeout(() => {
        lock = false;
      }, delay);
    }
  };

  if (lock) {
    bodyLockAdd(delay);
  } else {
    bodyLockRemove(delay);
  }
};

export const toggleShowPassword = (e) => {
  const parent = e.target.closest('.input-with-icon');
  const button = parent.querySelector('.icon-eye');
  const input = parent.querySelector('input.input');
  const inputType = input.getAttribute('type');

  button.classList.toggle('icon-eye--active');
  if (inputType === 'password') {
    input.setAttribute('type', 'text');
  } else {
    input.setAttribute('type', 'password');
  }
};

export const resetShowPassword = (e) => {
  const input = e.target;
  const parent = input.closest('.input-with-icon');
  const button = parent.querySelector('.icon-eye');

  button.classList.remove('icon-eye--active');
  input.setAttribute('type', 'password');
  input.selectionStart = input.value.length;
};

// Функция увеличения высоты Textarea
export const increaseHeightTextarea = (textarea) => {
  const height = textarea.scrollHeight + 'px';
  textarea.style.height = height;
};

// Функция проверки валидности даты
export const isValidDate = (date) => {
  return date instanceof Date;
};

/**
 * Получение иконки типа мероприятия
 *
 * @param name
 * @returns {any}
 */
export const getIconEventType = (name) => {
  let icon;

  switch (name) {
    case 'Интеллектуальная помощь':
      icon = '/img/events-type/intelegent.svg';
      break;
    case 'Культура и искусство':
      icon = '/img/events-type/culture.svg';
      break;
    case 'Спорт':
      icon = '/img/events-type/sport.svg';
      break;
    case 'Природа и Экология':
      icon = '/img/events-type/nature.svg';
      break;
    case 'Люди  с ОВЗ':
      icon = '/img/events-type/ovz.svg';
      break;
    case 'Медицина':
      icon = '/img/events-type/medicine.svg';
      break;
    case 'Образование и просвещение':
      icon = '/img/events-type/education.svg';
      break;
    case 'Технологии':
      icon = '/img/events-type/tehnology.svg';
      break;
    case 'Поиск Пропавших':
      icon = '/img/events-type/search.svg';
      break;
    case 'Права человека':
      icon = '/img/events-type/human-rights.svg';
      break;
    case 'Чрезвычайные ситуации':
      icon = '/img/events-type/emergancy.svg';
      break;
    case 'Помощь старшему поколению':
      icon = '/img/events-type/help-older.svg';
      break;
    case 'Дети':
      icon = '/img/events-type/children.svg';
      break;
    case 'Религия и духовное':
      icon = '/img/events-type/religion.svg';
      break;
    case 'Военно-патриотическая деятельность':
      icon = '/img/events-type/patriot.svg';
      break;
    default:
      icon = '/img/logo-small.svg';
      break;
  }

  return icon;
};

/**
 * Безопасно декодируем URI-строку
 *
 * @param {string} value
 * @returns {string}
 */
export const safeDecodeURI = (value: string): string => {
  try {
    return decodeURI(value);
  } catch (e) {
    console.log(e);
    return value;
  }
};

/**
 * образает текст в шаблоне vue по количеству указанных символов
 * @param text: исходный текст
 * @param length: количество символов,
 * которое требуется оставить в тексте для шаблона (по умолчанию = 20)
 */
 export const truncate = (text: string, length = 20) => {
  if (!text) return '';

  if (text.length <= length) {
    return text;
  }
  return `${text.substring(0, length)} ...`;
};
