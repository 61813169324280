

























































































import CardUser from '@/components/ui/CardUser.vue';
import { ROUTER_LINK } from '@/constants/constList';
import {
  getNameFormat,
  getInitialName,
  getAvatarUser,
} from '@/constants/constFormated';
import { Component, Vue } from 'nuxt-property-decorator';
import { Organization } from '~/declarations/organizations';

@Component<ActionsUser>({
  components: {
    CardUser,
  },
})
export default class ActionsUser extends Vue {
  openMenu: boolean = false;

  personalLink: string = ROUTER_LINK.personal;

  organizationLink: string = ROUTER_LINK.organization;

  get user() {
    const user = this.$auth.user;
    return user;
  }

  get organizationAvatars() {

    return this.$typedStore.getters['organization/getAvatars'];
  }

  get isUserActive() {
    const orgActive = this.$typedStore.state.organization.activeOrganization;
    if (orgActive) return false;
    return true;
  }

  get orgActive() {
    return this.$typedStore.getters['organization/getActiveAvatar'];
  }

  get userProfile() {
    return getAvatarUser(this.user);
  }

  get userName() {
    const name = getNameFormat(
      this.user?.first_name,
      this.user?.last_name,
      this.user?.middle_name,
      this.user?.id,
    );
    return name;
  }

  get userInitial() {
    return getInitialName(this.user?.first_name, this.user?.last_name);
  }

  get userAvatar() {
    return {
      url: this.user?.avatar,
      alt: this.userInitial,
    };
  }

  async handlerLogout() {
    await this.$auth.logout();
    // this.$router.push('/');
    this.$typedStore.commit('userFavorites/clearFavorites');
    this.$typedStore.commit('userSubscribe/clearAll');
    this.$typedStore.commit('organization/clearOrganizations');
    await this.$router.push('/');
    this.$sentry.setUser(null);
    //   потом убрать тоаст и консоль лог
    this.$toast.show('Выход совершен');
  }

  setActiveOrganization(id: Organization['id']) {
    if (this.$typedStore.state.organization.activeOrganization !== id) {
      this.$typedStore.dispatch('organization/setActiveOrganization', id);
      this.favoriteReplace();
    }
    this.closeMenuProfile();
  }

  setUserActive() {
    if (this.$typedStore.state.organization.activeOrganization) {
      this.$typedStore.commit('organization/clearActiveOrganization');
      this.favoriteReplace();
    }
    this.closeMenuProfile();
  }

  openMenuProfile() {
    this.openMenu = true;
  }

  closeMenuProfile() {
    this.openMenu = false;
  }

  favoriteReplace() {
    this.$typedStore.commit('userFavorites/clearFavorites');
    this.$typedStore.dispatch('userFavorites/getFavorites');
  }
};
