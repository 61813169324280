import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { Notification } from '~/declarations/shared';
import { StateInterface } from '~/store/index';

export interface NotifyStateInterface {
  notifications: Array<Notification>,
  isNewNotif: boolean,
}

export const state = (): NotifyStateInterface => ({
  notifications: [],
  isNewNotif: false,
});

export const mutations: MutationTree<NotifyStateInterface> = {
  setNotifications(state, data) {
    state.notifications = [...data];
  },
  deleteNotify(state, id) {
    const index = state.notifications.findIndex((el) => el.id === id);
    if (index > -1) {
      state.notifications.splice(index, 1);
      // this._vm.$set(state, 'favoriteOrganization', notify);
    }
  },
  setIsNotif(state, flag: boolean) {
    state.isNewNotif = flag;
  },
  clearNotifications(state) {
    state.notifications = [];
    state.isNewNotif = false;
  },
};

export const actions: ActionTree<NotifyStateInterface, StateInterface> = {
  async getNotifications({ state, commit, dispatch }) {
    if (this.$auth.loggedIn) {
      try {
        const res = await this.$axios
          .get('/api/notifications/')
          .then((res) => res.data.results as NotifyStateInterface['notifications']);
        // if (res.length !== state.notifications.length) {
        const notify = res.sort((a, b) => b.id - a.id);
        commit('setNotifications', notify);
        // }
        dispatch('searchNewNotify');
      } catch (e) {
        console.log(e);
      }
    }
  },
  searchNewNotify({ state, commit }) {
    const index = state.notifications.findIndex((el) => !el.is_read);
    if (index > -1) {
      commit('setIsNotif', true);
    } else {
      commit('setIsNotif', false);
    }
  },
  async deleteNotifications({ commit, dispatch }, id) {
    try {
      await this.$axios.delete(`/api/messages/${id}/`);
      commit('deleteNotify', id);
      dispatch('searchNewNotify');
    } catch (e) {
      console.log(e);
    }
  },
};

export const getters: GetterTree<NotifyStateInterface, StateInterface> = {};
