/*
TODO LIST
[ ] Вынести запросы и убрать дубли (запрос на получение кода повторяется )
[ ] Добить регистрацию ( запрос на регистрацию )
[ ] disabled кнопки при отправки запроса !! ( это желательно на все формы )
*/
<template>
  <ModalBody
    :title="title"
    :is-backbutton="true"
    @closeModal="closeModal"
    @goBack="goBack"
  >
    <div class="modal__content">
      <div v-if="errorsRequest.length" class="errors">
        <p v-for="error in errorsRequest" :key="error">{{ error }}</p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }" name="codeVerify" slim>
        <form class="form" @submit.prevent="handleSubmit(submitCode)">
          <p class="form__text">
            На ваш мобильный телефон отправлен код подтверждения. Введите его
            ниже, чтобы продолжить.
          </p>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="codeValid"
            :rules="{ required: true }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="code">
              Код подтверждения<span class="input-req"> *</span>
            </label>
            <input
              id="code"
              v-model="code"
              type="number"
              class="input"
              :class="{ 'input--error': validated && errors.length > 0 }"
              autocomplete="off"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
                errors[0]
              }}</span>
          </ValidationProvider>
          <button class="btn btn-green btn--arrow form__button" type="submit">
            <span>Продолжить</span>
            <svg-icon
              name="arrow"
              width="6px"
              height="10px"
              stroke="#ffffff"
            ></svg-icon>
          </button>

          <p v-if="!isTimeLeftZero" class="form__text form__text--bottom">
            Повторный код можно получить через
            <span>{{ timeLeft }} сек.</span>
          </p>
          <div v-else-if="isTimeLeftZero" class="form__link">
            <button @click.prevent="handlerGetCode">
              Получить повторный код
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </ModalBody>
</template>

<script>
import ModalBody from '@/components/Modals/ModalBody/ModalBody.vue';
import { AUTH_COMPONENTS, ROUTER_LINK } from '@/constants/constList';

export default {
  name: 'ModalCodeRegister',
  components: {
    ModalBody,
  },
  props: {
    params: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      title: 'Код безопастности',
      timeLeft: 120,
      isTimeLeftZero: false,
      code: '',
      errorsRequest: [],
    };
  },
  created() {
    this.countDownTimer();
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    countDownTimer() {
      if (this.timeLeft > 0) {
        setTimeout(() => {
          this.timeLeft -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.isTimeLeftZero = true;
      }
    },
    goBack() {
      this.$emit('openModalNext', { modal: AUTH_COMPONENTS.registration });
    },
    async handlerGetCode() {
      this.isTimeLeftZero = false;
      this.timeLeft = 120;
      this.countDownTimer();

      const dataRequest = { phone: this.params.phone };
      await this.$api.auth.sendCode(dataRequest);
    },

    async submitCode() {
      try {
        const res = await this.$axios.post('/api/verify-code/', {
          code: this.code,
        });

        if (!res.data.result) throw new Error('Введён не верный код!');

        const registration = await this.$axios.post('/api/save-registration/', {
          phone_number: this.params.phone,
          first_name: this.params.firstName,
          last_name: this.params.lastName,
          middle_name: this.params.middleName,
          email: this.params.email,
          password1: this.params.password,
          password2: this.params.password2,
          is_confirm: true,
        });

        if (!registration.data.result)
          throw new Error('Ошибка при регистрации попробуйте повторить позже');
        const data = {
          username: this.params.phone,
          password: this.params.password,
        };

        this.errorsRequest = [];
        await this.$auth.loginWith('local', { data });
        await Promise.all([
          this.$typedStore.dispatch('userFavorites/getFavorites'),
          this.$typedStore.dispatch('userSubscribe/getSubscribe'),
          this.$typedStore.dispatch('organization/getOrganizations'),
        ]);
        this.$toast.success('Спасибо за регистрацию!');
        this.$toast.info(
          'Заполните профиль для принятия участия в Мероприятиях',
        );
        this.closeModal();
        await this.$router.push({ path: ROUTER_LINK.mySettings.profile });
      } catch (e) {
        if (e instanceof Error) {
          this.errorsRequest = [e.message];
        }
      }
    },
  },
};
</script>
