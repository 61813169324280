


























// styles
// import 'assets/scss/_common.scss';

import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import { Component, mixins } from 'nuxt-property-decorator';
import HasHeaderData from '@/mixins/has-header-data';
import Lk from '~/mixins/lk';

@Component({
  name: 'Organization',
  components: {
    Header,
    Footer,
    BreadCrumbs: () => import('@/components/ui/BreadCrumbs.vue'),
    PersonalHeader: () => import('@/components/Personal/PersonalHeader.vue'),
    ModalManager: () => import('@/components/Modals/ModalManager.vue'),
  },
})
export default class Organization extends mixins(HasHeaderData) {
  isModalManagerOpen: boolean = false;

  mounted() {
    const isActiveOrg = this.$typedStore.state.organization.activeOrganization;
    if (!isActiveOrg) {
      this.$toast.show('Не выбран кабинет Организации!');
      this.$router.push('/');
    }
    const hash = this.$route.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) this.$smoothScroll({ scrollTo: element });
    }
  }

  addManager() {
    this.isModalManagerOpen = true;
  }

  closeModal() {
    this.isModalManagerOpen = false;
  }
};
