import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { FAVORITE_TYPES } from '@/constants/constList';
import { StateInterface } from '~/store/index';
import { Organization } from '~/declarations/organizations';
import { HasId, UsedAsFavorite } from '~/declarations/shared';


export interface UserFavoriteInterface {
  dispatchFavorites: null | Date | number;
  maxTimeDispatch: number; // минуты
  favoriteEvent: UsedAsFavorite;
  favoriteUser: UsedAsFavorite;
  favoriteOrganization: UsedAsFavorite<Organization>;
}

export const state = (): UserFavoriteInterface => ({
  dispatchFavorites: null,
  maxTimeDispatch: 1, // минуты
  favoriteEvent: {},
  favoriteUser: {},
  favoriteOrganization: {},
});

export const mutations: MutationTree<UserFavoriteInterface> = {
  setFavorites(state, { type, data }: {type: string, data: Array<HasId>}) {
    const favorite = data.reduce((acc: UsedAsFavorite, el) => {
      acc[el.id] = el;
      return acc;
    }, {});
    if (type === 'event') {
      // @ts-ignore
      this._vm.$set(state, 'favoriteEvent', favorite);
    }
    if (type === 'user') {
      // @ts-ignore
      this._vm.$set(state, 'favoriteUser', favorite);
    }
    if (type === 'organization') {
      // @ts-ignore
      this._vm.$set(state, 'favoriteOrganization', favorite);
    }
  },
  setDateDispatch(state) {
    state.dispatchFavorites = new Date();
  },
  addNewFavorite(state, { type, newFavorite }) {
    if (type === 'event') {
      const favorite = Object.assign({}, state.favoriteEvent, {
        [newFavorite.id]: newFavorite,
      });
      // @ts-ignore
      this._vm.$set(state, 'favoriteEvent', favorite);
    }
    if (type === 'user') {
      const favorite = Object.assign({}, state.favoriteUser, {
        [newFavorite.id]: newFavorite,
      });

      // @ts-ignore
      this._vm.$set(state, 'favoriteUser', favorite);
    }
    if (type === 'organization') {
      const favorite = Object.assign({}, state.favoriteOrganization, {
        [newFavorite.id]: newFavorite,
      });
      // @ts-ignore
      this._vm.$set(state, 'favoriteOrganization', favorite);
    }
  },
  removeFavoriteID(state, { type, id }: { type: string, id: number }) {
    if (type === 'event') {
      const favorite = Object.assign({}, state.favoriteEvent);
      delete favorite[id];
      // @ts-ignore
      this._vm.$set(state, 'favoriteEvent', favorite);
    }
    if (type === 'user') {
      const favorite = Object.assign({}, state.favoriteUser);
      delete favorite[id];
      // @ts-ignore
      this._vm.$set(state, 'favoriteUser', favorite);
    }
    if (type === 'organization') {
      const favorite = Object.assign({}, state.favoriteOrganization);
      delete favorite[id];
      // @ts-ignore
      this._vm.$set(state, 'favoriteOrganization', favorite);
    }
  },
  clearFavorites(state) {
    state.favoriteEvent = {};
    state.favoriteUser = {};
    state.favoriteOrganization = {};
    state.dispatchFavorites = null;
  },
};

export const actions: ActionTree<UserFavoriteInterface, StateInterface> = {
  async getFavorites({ rootState, state, commit }) {
    const dateDispatch: number = +(new Date()) - state.maxTimeDispatch * 6000;
    // WTF: ЧТо за херня
    // @ts-ignore
    if (!state.dispatchFavorites && state.dispatchFavorites < dateDispatch) {
      const activeOrganization = rootState.organization.activeOrganization;
      const eventsUrl = activeOrganization
        ? `/api/organization-favourites-event/${activeOrganization}/`
        : '/api/user-favourites-event/';
      const usersUrl = activeOrganization
        ? `/api/organization-favourites-user/${activeOrganization}/`
        : '/api/user-favourites-user/';
      const organizationUrl = activeOrganization
        ? `/api/organization-favourites-organization/${activeOrganization}/`
        : '/api/user-favourites-organization/';
      try {
        const { data: resEvent } = await this.$axios.get(eventsUrl);
        commit('setFavorites', {
          type: FAVORITE_TYPES.events,
          data: resEvent.results,
        });

        const { data: resUsers } = await this.$axios.get(usersUrl);
        commit('setFavorites', {
          type: FAVORITE_TYPES.users,
          data: resUsers.results,
        });

        if (organizationUrl) {
          const { data: resOrg } = await this.$axios.get(organizationUrl);
          commit('setFavorites', {
            type: FAVORITE_TYPES.organizations,
            data: resOrg.results,
          });
        }
        commit('setDateDispatch');
      } catch (e) {
        console.log(e);
      }
    }
  },

  async addFavorites({ rootState, commit }, { type, element }) {
    commit('addNewFavorite', { type, newFavorite: element });
    const activeOrganization = rootState.organization.activeOrganization;
    const addUrl = activeOrganization
      ? `/api/add-organization-favourites/${activeOrganization}/`
      : '/api/add-user-favourites/';
    try {
      await this.$axios.post(addUrl, {
        type,
        entity: element.id,
      });
      return true;
    } catch (e: any) {
      if (e?.response?.status === 401) {
        // @ts-ignore
        this.$toast.show('Авторизуйтесь для добавление в избранное!');
      }
      commit('removeFavoriteID', { type, id: element.id });
      return false;
    }
  },
  async removeFavorite({ rootState, commit }, { type, element }) {
    commit('removeFavoriteID', { type, id: element.id });
    const activeOrganization = rootState.organization.activeOrganization;
    // /api/add-organization-favourites/${activeOrganization}/
    const removeUrl = activeOrganization
      ? `/api/delete-organization-favourites/`
      : '/api/delete-user-favourites/';
    const objectRequest: {
      type: string,
      entity: number,
      organization_id?: number
    } = {
      type,
      entity: element.id,
    };
    if (activeOrganization) {
      objectRequest.organization_id = activeOrganization;
    }
    try {
      await this.$axios.post(removeUrl, objectRequest);
      return true;
    } catch (e: any) {
      console.log(e);
      console.log(e.messagge);
      commit('addNewFavorite', { type, newFavorite: element });
      return false;
    }
  },
};

export const getters: GetterTree<UserFavoriteInterface, StateInterface> = {
  isFavorite: (state) => {
    return (type: string, id: number) => {
      if (type === 'event') {
        const favorites = state.favoriteEvent;
        const element = favorites[id];
        return !!element;

      }
      if (type === 'user') {
        const favorites = state.favoriteUser;
        const element = favorites[id];
        return !!element;

      }
      if (type === 'organization') {
        const favorites = state.favoriteOrganization;
        const element = favorites[id];
        return !!element;

      }

      return false;
    };
  },
};
