import { getIconEventType } from '~/constants/constFunctions';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { StateInterface } from '@/store';
import { EventCategory, ProfileEnums } from '~/declarations/shared';

export interface EnumsInterface {
  profileEnums: ProfileEnums;
  eventCategories: Array<EventCategory>;
  isSetProfileEnums: boolean;

}

export const state = (): EnumsInterface => ({
  profileEnums: {},
  eventCategories: [],
  isSetProfileEnums: false,
});

export const actions: ActionTree<EnumsInterface, StateInterface> = {
  async getProfileEnums({ state, commit }) {
    const isSetEnums = state.isSetProfileEnums;
    if (!isSetEnums) {
      try {
        await this.$axios.get<{ result: Array<EnumsInterface['profileEnums']> }>('/api/get-profile-enums/').then((res) => {
          if (res.data.result) {
            const result = Array.from(res.data.result).reduce((acc, el) => {
              const keys = Object.keys(el);
              keys.forEach((key) => {
                acc[key] = el[key];
              });
              return acc;
            }, {});
            commit('setProfileEnums', result);
          } else {
            throw new Error('Пользователь не зарегистрирован');
          }
        });
        // /api/event_category/ - OLD

        await this.$axios.get<{ results: EnumsInterface['eventCategories'] }>('/api/event_types/').then((res) => {
          if (res.data.results) {
            // const result = Array.from(res.data.results).filter(
            //   (el) => el.status === 'active'
            // );
            const result = Array.from(res.data.results).map((el, i) => {
              const icon = getIconEventType(el.name);
              return Object.assign(el, { icon });
            });
            commit('setEventCategory', result);
          } else {
            throw new Error('Что то пошло не так...');
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  },
};

export const mutations: MutationTree<EnumsInterface> = {
  setProfileEnums(state, data) {
    state.profileEnums = Object.assign(data, {});
    state.isSetProfileEnums = true;
  },
  setEventCategory(state, data) {
    state.eventCategories = [...data];
    state.isSetProfileEnums = true;
  },
};

export const getters: GetterTree<EnumsInterface, StateInterface> = {
  getTextOptionForId: (state) => (key: string, id: number) => {
    if (state.profileEnums[key]) {
      const index = state.profileEnums[key].findIndex((el) => el[0] === id);
      if (index >= 0) {
        return state.profileEnums[key][index][1];
      }
    }
    return '';
  },
  getProfileForKey: (state) => (key: string) => {
    if (state.profileEnums[key]) {
      const enums = state.profileEnums[key].map((el) => {
        return {
          id: el[0],
          value: el[1],
        };
      });

      return enums;
    }
    return [];
  },
  getProfileForKeyObject: (state) => (key: string): Record<number, string> => {
    if (state.profileEnums[key]) {
      const enums = state.profileEnums[key].reduce((acc, item) => {
        acc[item[0]] = item[1];
        return acc;
      }, {} as Record<string, string>);

      return enums;
    }
    return {};
  },
  getCategoryForList: (state) => {
    return state.eventCategories.map((el: EventCategory) => {
      return {
        id: el.id,
        value: el.name,
      };
    });
  },
  getEventsCategoryObject: (state): Record<EventCategory['id'], EventCategory> => {
    return state.eventCategories.reduce((acc, el) => {
      acc[el.id] = el;
      return acc;
    }, {} as Record<number, EventCategory>);
  },
};
