<template>
  <div id="loader"></div>
</template>

<script>
export default {
  name: 'LoaderV',
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fafafa;
  opacity: 0.5;
  z-index: 999;
}
</style>