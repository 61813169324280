






















// import { ROUTER_LINK } from '@/constants/constList';
import ContainerSm from '@/components/ui/ContainerSm.vue';
import { ROUTER_LINK } from '@/constants/constList';
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  components: {
    ContainerSm,
  },
})
export default class FooterCopyrigth extends Vue {
  @Prop({ type: String, required: true })
  copyrightText!: string;

  @Prop({ type: String, required: true })
  agreementText!: string;

  @Prop({ type: String, required: true })
  privacyText!: string;

  classLink = 'footer__copy-link';

  ROUTER_LINK = ROUTER_LINK;
};
