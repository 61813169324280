import { AxiosInstance } from 'axios';
import Events from './events';
import Auth from './auth';
import Content from './content';
import { Shared } from '~/api/shared';
import News from './news';

export default (axios: AxiosInstance) => ({
  events: new Events(axios),
  auth: new Auth(axios),
  content: new Content(axios),
  shared: new Shared(axios),
  news: new News(axios),
})
