
















































// import SocialsAuth from '@/components/Socials/SocialsAuth.vue';
import { maskPhone, phoneMask, unmask } from '@/constants/constFormated';
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import ModalBody from '~/components/Modals/ModalBody/ModalBody.vue';
import { AUTH_COMPONENTS } from '~/constants/constList';
import BackendHelpers from '~/mixins/use-backend';

@Component({
  name: 'ModalPhoneConfirm',
  components: {
    ModalBody,
  },
})
export default class ModalPhoneConfirm extends mixins(BackendHelpers) {
  @Prop({ type: Object, default: () => ({}) })
  params!: { phoneNumber: string };

  title = 'Подтверждение номера телефона';

  errorsRequest = [];

  phone = '';

  phoneMask = phoneMask;

  // eslint-disable-next-line require-await
  async submitPhoneConfirmation() {
    const phone = unmask(this.phone, phoneMask);
    await this.makeRequest(() => this.$api.auth.sendCode({ phone }))

    this.$emit('openModalNext', {
      modal: AUTH_COMPONENTS.phoneConfirmCode,
      data: { phone },
    });
  }

  mounted() {
    this.phone = maskPhone(this.$typedStore.state.socialAuth.phone);
  }
};
