






































import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import ModalAuth, { AllowedSocialNetworks } from '@/components/Modals/ModalAuth.vue';
import ModalSearch from '@/components/Modals/ModalSearch.vue';

import { addRemoveBodyClass } from '@/constants/constFunctions';
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import LoaderV from '~/components/ui/LoaderV.vue';
import { SocialNetworkRedirect } from '~/declarations/shared';
import { AUTH_COMPONENTS } from '~/constants/constList';

@Component<DefaultLayout>({
  components: {
    Header,
    LoaderV,
    Footer,
    ModalAuth,
    ModalSearch,
  },
  watch: {
    '$route.query.authModal'(value) {
      if (value && !this.$auth.loggedIn) {
        this.isModalAuthOpen = true;
        this.isBackRoute = true;
        this.$toast.show('Пожалуйста авторизуйтесь!');
      }
    },

    isModalAuthOpen(value) {
      // const header = this.$refs.header.$el;
      // const body = document.querySelector('body');
      addRemoveBodyClass(value);
    },

    isSearchOpen(value) {
      // const header = this.$refs.header.$el;
      // const body = document.querySelector('body');
      addRemoveBodyClass(value);
    },
  },
})
export default class DefaultLayout extends Vue {
  isModalAuthOpen: boolean = false;

  isModalConfirmOpen: boolean = false;

  isSearchOpen: boolean = false;

  isBackRoute: boolean = false;

  isLoading: boolean = false;

  phoneNumber = '';

  AUTH_COMPONENTS = AUTH_COMPONENTS;

  @Watch('$route.query.phoneConfirmation')
  toggleConfirmationModal(value: boolean) {
    this.isModalConfirmOpen = value;
  }

  mounted() {
    if (this.$route.query?.phoneConfirmation) {
      this.isModalConfirmOpen = true;
      this.phoneNumber = (this.$route.query?.phone as string | null) || ''
      this.$toast.show('Пожалуйста подтвердите Ваш номер телефона!');
    }

    if (this.$route.query?.error === 'access_denied') {
      this.$router.push('/');
    }
  }

  openModalAuth() {
    this.isModalAuthOpen = true;
  }

  openModalSearch() {
    this.isSearchOpen = true;
  }

  closeModal() {
    this.isModalAuthOpen = false;
    if (this.$route.query?.authModal) {
      this.$router.replace({
        name: this.$route.name as string,
        query: { ...this.$route.query, authModal: 'false' },
      });
    }
  }

  closeSearch() {
    this.isSearchOpen = false;
  }


};
