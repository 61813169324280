import { MutationTree } from 'vuex';
import { OrgProfile, UserProfile } from '@/declarations/profiles';

export interface ProfileInfoStatisticInterface {
  userProfile: UserProfile;
  statisticMain: Array<any>;
  statisticOther: Array<any>;
  awards: Array<any>;
  orgProfile: OrgProfile,
  statisticOrg: Array<any>;
}

export const state = (): ProfileInfoStatisticInterface => ({
	userProfile: {
		name: '',
		initial: '',
		image: undefined,
	},
	statisticMain: [],
	statisticOther: [],
	awards: [],
	orgProfile: {
		name: '',
		image: undefined,
	},
	statisticOrg: [],
});

export const mutations: MutationTree<ProfileInfoStatisticInterface> = {
	setUser(state, { name, initial, image }) {
		state.userProfile.name = name;
		state.userProfile.initial = initial;
		state.userProfile.image = image;
	},
	setStatisticMain(state, statistic) {
		state.statisticMain = [...statistic];
	},
	setStatisticOther(state, statistic) {
		state.statisticOther = [...statistic];
	},
	setAwards(state, awards) {
		state.awards = [...awards];
	},
	setOrg(state, { name, image }) {
		state.orgProfile.name = name;
		state.orgProfile.image = image;
	},
	setStatisticOrg(state, statisticOrg) {
		state.statisticOrg = [...statisticOrg];
	},

};
