/*
 TODO list
   [ ] Возможно вынести форму в отдельный компанент так как она дублируется с подверждение телфона в регистрации
*/

<template>
  <ModalBody :title="title" @closeModal="closeModal">
    <div class="modal__content">
      <div v-if="errorsRequest.length" class="errors">
        <p v-for="error in errorsRequest" :key="error">{{ error }}</p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }" name="codeVerify" slim>
        <form class="form" @submit.prevent="handleSubmit(submitCode)">
          <p class="form__text">
            На ваш мобильный телефон отправлен код подтверждения. Введите его
            ниже, чтобы продолжить.
          </p>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="codeValid"
            :rules="{ required: true }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="code">
              Код подтверждения<span class="input-req"> *</span>
            </label>
            <input
              id="code"
              v-model="code"
              type="number"
              class="input"
              :class="{ 'input--error': validated && errors.length > 0 }"
              autocomplete="off"
            />
            <span v-if="validated && errors.length > 0" class="label__error">{{
              errors[0]
            }}</span>
          </ValidationProvider>
          <button class="btn btn-green btn--arrow form__button" type="submit">
            <span>Продолжить</span>
            <svg-icon
              name="arrow"
              width="6px"
              height="10px"
              stroke="#ffffff"
            ></svg-icon>
          </button>

          <p v-if="!isTimeLeftZero" class="form__text form__text--bottom">
            Повторный код можно получить через
            <span>{{ timeLeft }} сек.</span>
          </p>
          <div v-else-if="isTimeLeftZero" class="form__link">
            <button @click.prevent="handlerGetCode">
              Получить повторный код
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </ModalBody>
</template>

<script>
import ModalBody from '@/components/Modals/ModalBody/ModalBody.vue';
import { AUTH_COMPONENTS } from '@/constants/constList';

export default {
  name: 'ModalCodeLogin',
  components: {
    ModalBody,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: 'Код безопастности',
      timeLeft: 120,
      isTimeLeftZero: false,
      code: '',
      errorsRequest: [],
    };
  },
  created() {
    this.countDownTimer();
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async submitCode() {
      try {
        const res = await this.$axios.post(
          '/api/check-code-for-password-recovery/',
          { code: this.code }
        );
        if (!res.data.result) throw new Error('Введён не верный код!');

        this.$emit('openModalNext', {
          modal: AUTH_COMPONENTS.newPass,
          data: {
            token: res.data.token,
            phone: this.params.phone,
          },
        });
      } catch (e) {
        if (e instanceof Error) {
          this.errorsRequest = [e.message];
        } else {
          console.log(e);
        }
      }
    },
    countDownTimer() {
      if (this.timeLeft > 0) {
        setTimeout(() => {
          this.timeLeft -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.isTimeLeftZero = true;
      }
    },
    async handlerGetCode() {
      this.isTimeLeftZero = false;
      this.timeLeft = 120;
      this.countDownTimer();

      const dataRequest = { phone: this.params.phone };
      await this.$api.auth.sendCode(dataRequest);
    },
  },
};
</script>
