import { Component, Vue } from 'nuxt-property-decorator';
import { BlockContent } from '~/declarations/content';
import { NAV_LIST } from '~/constants/constList';

@Component
export default class HasMenu extends Vue {

  get menu(): BlockContent['menuItems'] {
    return this.$typedStore.state.content.content.menu.menuItems;
  }

  get menuContent(): BlockContent {
    return this.$typedStore.state.content.content.menu;
  }

  get navList() {
    return NAV_LIST(this.menu);
  }
}
