/* eslint-disable import/no-duplicates */
import { differenceInISOWeekYears, format, formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FAVORITE_TYPES } from './constList';
import { Event, EventCard } from '~/declarations/events';

// Форматирование номера телефона
export const phoneFormated = (phone: string) => {
  const phoneReplace = phone.replace(/[^\d]/gm, '');

  if (phoneReplace.length === 11) {
    return Array.from(phoneReplace).reduce((acc, num, index) => {
      switch (index) {
        case 0: {
          if (Number(num) !== 8) {
            return `+${num} (`;
          }
          return num + ' (';
        }

        case 3: {
          return acc + num + ') ';
        }

        case 6: {
          return acc + num + ' ';
        }
        case 8: {
          return acc + num + '-';
        }
        default: {
          return acc + num;
        }
      }
    }, '');
  }

  return phone;
};

export const phoneMask = '+7 (###) ### ##-##';

export const unmask = (maskedValue: string, mask: string): string => {
  const defaultTokens = ['#', 'X', 'S', 'A', 'a', '!'];
  let unmaskedValue = '';
  let isToken;

  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false;
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] === defaultTokens[j]) {
        isToken = true;
      }
    }

    if (isToken) {
      unmaskedValue += maskedValue[i];
    }
  }
  // console.log(unmaskedValue);
  return unmaskedValue;
};

/**
 *
 * @param {string} phone
 * @returns {string}
 */
export const maskPhone = (phone: string): string => {
  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2 $3-$4');
}

// Функция представления даты в формате dd.MM.yyyy
export const createDate = (value: string): Date | string => {
  if (!value) return '';
  const dateArr = value.split('-');
  const year = Number(dateArr[0]);
  const month = Number(dateArr[1]);
  const day = Number(dateArr[2]);
  return new Date(year, month - 1, day);
};

export const isDateMoreNow = (dateStr: string, timeStr: string): boolean => {
  const nowDate = new Date();
  const date = createTimeDate(dateStr, timeStr);

  return date > nowDate;
};

export const createTimeDate = (date: string, time: string): string | Date => {
  if (!date) return '';

  const dateArr = date.split('-');
  const year = Number(dateArr[0]);
  const month = Number(dateArr[1]);
  const day = Number(dateArr[2]);

  if (!time) return new Date(year, month - 1, day);
  const timeArr = time.split(':');
  const h = Number(timeArr[0]);
  const m = Number(timeArr[1]);
  const s = Number(timeArr[2]);
  return new Date(year, month - 1, day, h, m, s);
};

export const getRequestFormatDate = (value: null | string): string => {
  if (!value) return '';
  const date = new Date(value);
  return format(date, 'yyyy-MM-dd');
};

export const getRequestFormatTime = (value: null | string): string => {
  if (!value) {
    return format(new Date(), 'HH:mm:ss');
  }
  const date = new Date(value);
  return format(date, 'HH:mm:ss');
};

export const initDate = (value: string | null): string => {
  if (!value) return '';
  return format(createDate(value) as Date, 'dd.MM.yyyy');
};

export const formatDateTime = (date: string | null): string => {
  if (!date) return '';
  const newDate = new Date(date);
  return format(newDate, 'dd.MM.yyyy HH:mm');
};

export const formatDateTimeToDate = (date: string | null): string => {
  if (!date) return '';
  const newDate = new Date(date);
  return format(newDate, 'dd.MM.yyyy');
};

// Функция расчета возраста
export const getAge = (dateString: string | null): string => {
  if (!dateString) return '';
  const birthDate = createDate(dateString) as Date;
  const age = formatDistanceToNowStrict(birthDate, { locale: ru });
  return age;
};

export const getAgeYearNumber = (dateString: string | null): number | string => {
  if (!dateString) return '';
  const birthDate = createDate(dateString) as Date;
  const age = differenceInISOWeekYears(new Date(), birthDate);
  return age;
};

// Функция для представления времени в формате 00:00
export const initTime = (value: string | null): string => {
  if (!value) return '';
  return value.slice(0, -3);
};

// Функция для формата имени
export const getNameFormat = (name: string, lastName: string, middleName: string | null, id: number = 0): string => {
  const fullName = new Set([lastName, name, middleName]);
  return [...fullName].join(' ') || `User-${Math.floor(id ** 3 / 3)}`;
};
// Функция для получение инициалов
export const getInitialName = (name: string, lastName: string): string => {
  const nameFirstLetter = name ? name[0].toUpperCase() : '';
  const lastNameFirstLetter = lastName ? lastName[0].toUpperCase() : '';
  return nameFirstLetter + lastNameFirstLetter;
};

// функция по склонению слов от числа
export const declOfNum = (number: number, titles: Array<string>): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
    ];
};

// функция устанавливает стору для шапки профиля
export const getProfileHeader = (store) => {
  const user = store.state.auth.user;
  if (!user) return;
  const fullName = getNameFormat(
    user.first_name,
    user.last_name,
    user.middle_name,
  );
  const initial = getInitialName(user.first_name, user.last_name);
  const userProfile = {
    name: fullName || `user-${user.id}`,
    initial,
    image: null,
  };
  const statisticMain = [
    {
      id: 1,
      name: 'Рейтинг волонтёра',
      type: 'rating',
      value: 3,
    },
    {
      id: 2,
      name: 'Рейтинг организатора',
      type: 'rating',
      value: 2.5,
    },
    {
      id: 3,
      name: 'Уровень достижения',
      value: 1,
      type: 'level',
    },
  ];
  let awards: any = [];
  const titleCertificates = ['сертификат', 'сертификата', 'сертификатов'];
  const countCertificates = 24;
  // подсчет количества сертификатов
  const certificates = {
    id: 4,
    name: 'Сертификаты',
    type: 'link',
    value: `${countCertificates} ${declOfNum(
      countCertificates,
      titleCertificates,
    )}`,
    url: '#certificates',
  };
  // подсчет количества часов
  const countWorkHours = 360;
  const titleWorkHours = ['час', 'часа', 'часов'];
  const workHours = {
    id: 5,
    name: 'Количество отработанных часов',
    type: 'link',
    value: `${countWorkHours} ${declOfNum(countWorkHours, titleWorkHours)}`,
    url: '#events',
  };
  const statisticOther: any = [];
  statisticOther.push(certificates);
  statisticOther.push(workHours);

  // список наград
  const awardList = Array.from({ length: 10 }, (e) => ({
    name: `Награда ${e}`,
  }));
  awards = [...awardList];

  // профиль организатора
  const org = store.state.auth.org;
  const orgProfile = {
    name: org,
    image: null,
  };
  // рейтинг организатора
  const statisticOrg = [
    {
      id: 1,
      name: 'Рейтинг',
      type: 'rating',
      value: 4.5,
    },
    {
      id: 4,
      name: 'Сертификаты',
      type: 'link',
      value: `${countCertificates} ${declOfNum(
        countCertificates,
        titleCertificates,
      )}`,
      url: '#certificates',
    },
  ];

  store.commit('profileInfoStatistic/setOrg', orgProfile);
  store.commit('profileInfoStatistic/setStatisticOrg', statisticOrg);

  store.commit('profileInfoStatistic/setUser', userProfile);
  store.commit('profileInfoStatistic/setStatisticMain', statisticMain);
  store.commit('profileInfoStatistic/setStatisticOther', statisticOther);
  store.commit('profileInfoStatistic/setAwards', awards);
};

// функция устанавливает стору для шапки профиля организатора
export const getProfileHeaderOrg = (store) => {
  // профиль организатора
  const org = store.state.auth.org;
  const orgProfile = {
    name: org,
    image: null,
  };
  // рейтинг организатора
  const statisticOrg: any = [
    {
      id: 1,
      name: 'Рейтинг',
      type: 'rating',
      value: 4.5,
    },
  ];

  const titleCertificates = ['сертификат', 'сертификата', 'сертификатов'];
  const countCertificates = 24;

  // подсчет количества сертификатов
  const certificates = {
    id: 4,
    name: 'Сертификаты',
    type: 'link',
    value: `${countCertificates} ${declOfNum(
      countCertificates,
      titleCertificates,
    )}`,
    url: '#certificates',
  };

  statisticOrg.push(certificates);

  store.commit('profileInfoStatistic/setOrg', orgProfile);
  store.commit('profileInfoStatistic/setStatisticOrg', statisticOrg);
};

export const formatedImageUrl = (url) => {
  if (!url) return '';
  if (!url.includes('/media/')) return url;
  const imageArr = url.split('/media/');
  return imageArr.length > 1
    ? '/media/' + imageArr[1]
    : '/media/' + imageArr[0];
};

export const formatedNameFile = (url) => {
  if (!url) return '';
  if (!url.includes('/event_files/')) return url;
  const fileArr = url.split('/event_files/');
  return fileArr.length > 1
    ? fileArr[1]
    : "File";
};

export const getAvatarCard = (id, name, lastName, middleName, image) => {
  const fullName = getNameFormat(name, lastName, middleName, id);
  const initital = getInitialName(name, lastName);
  const avatarUrl = formatedImageUrl(image);
  const avatar = {
    url: avatarUrl,
    alt: fullName,
  };

  return {
    nameUser: fullName,
    initial: initital,
    image: avatar,
  };
};

export const getAvatarUser = (user) => {
  if (!user)
    return {
      nameUser: '',
      initial: '',
      image: {
        url: '',
        alt: '',
      },
    };
  const {
    id,
    first_name: name,
    last_name: lastName,
    middle_name: middleName,
    avatar,
  } = user;

  return getAvatarCard(id, name, lastName, middleName, avatar);
};

export const getAvatarOrganization = (organization) => {
  if (!organization)
    return {
      nameUser: '',
      initial: '',
      image: {
        url: '',
        alt: '',
      },
    };
  const { id, name } = organization;
  let logoUrl = '';
  if (organization.organization_profile) {
    const { logo } = organization.organization_profile;
    logoUrl = logo;
  }

  const objAvatar = getAvatarCard(id, name, '', '', logoUrl);

  return Object.assign(objAvatar, {
    svgIcon: {
      name: 'organization',
      stroke: '#838383',
    },
    initial: '',
  });
};

export const formatForCardEvent = (event: Event): EventCard => {
  const image = formatedImageUrl(event.image);
  const participationFormat = event.participation_format;
  const description = event.name
    ? event.name.length > 100
      ? event.name.slice(0, 90) + '...'
      : event.name
    : '';

  let avatarImg = '';
  let name: any = '';
  const typeAuthor = event.owner_type;
  let typeUrl = FAVORITE_TYPES.users;
  let authorId: any = event.owner;
  if (typeAuthor === 'organization' && event.organisation) {
    name = event.organisation_short_name || event.organisation_name;
    avatarImg = formatedImageUrl(event.organisation_logo);
    typeUrl = FAVORITE_TYPES.organizations;
    authorId = event.organisation;
  } else {
    name = getNameFormat(
      event.first_name,
      event.last_name,
      event.middle_name,
      event.owner,
    );
    avatarImg = formatedImageUrl(event.avatar);
  }
  return {
    id: event.id,
    image,
    rate: 3,
    startDate: event.start_date,
    location: event.city || '',
    description,
    participationFormat,
    author: {
      id: authorId,
      name,
      image: avatarImg,
      typeUrl,
    },
    freePlaceVolunteer: event.volunteers_place_total_count - event.volunteers_place_occupied_count,
    totalPlaceVolunteer: event.volunteers_place_total_count
  };
};

//  Форматирование ссылки на YouTube видео

/**
 * Регулярное выражение для определения какого вида ссылка на
 * YouTube, watch или embed
 */
export const YouTubeWatchLink = /(https:\/\/www\.youtube\.com\/watch\?v=|https:\/\/youtu\.be\/)(?<VIDEO_ID>.{11})\/?/;

/**
 * Шаблон для генерации embed ссылки YouTube на видео
 */
const YouTubeEmbedLink = 'https://www.youtube.com/embed/VIDEO_ID';

/**
 * Заменяем _watch_ ссылкку на YouTube видео, на _embed_
 *
 * @param link
 */
export const youTubeEmbedifier = (link: string): string => {
  if (YouTubeWatchLink.test(link)) {
    const match = link.match(YouTubeWatchLink);
    return YouTubeEmbedLink.replace('VIDEO_ID', match?.groups?.VIDEO_ID || '');
  }
  return  link;
}
