import { MutationTree } from 'vuex'

export interface CashStateInterface {
  authPlugins: boolean;
};

export const state = (): CashStateInterface => ({
  authPlugins: false,
});


export const mutations: MutationTree<CashStateInterface>  = {
  setAuthPlugins(state) {
    state.authPlugins = true;
  },
};
