




























































// test
import { fetchTest } from '@/constants/constTest';

import { ROUTER_LINK } from '@/constants/constList';

import ButtonV from '@/components/ui/ButtonV.vue';
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  components: { ButtonV },
})
export default class SubscribeForm extends Vue {
  @Prop({type: String, required:true})
  title!: string;

  @Prop({type: String, required:true})
  note!: string;

  subscribeEmail = '';

  agreementLink = ROUTER_LINK.agreement;

  isSubscribe = false;

  async onSubmit() {
    const { data } = await fetchTest({ status: 'succses' });

    if (data.status === 'succses') {
      this.isSubscribe = true;
    }
  }
};
