import BaseApiClass from "./base-api-class";
import {FilterParams, Like, NewsCategory, NewsImage, Paginated, StateNews, } from "~/declarations/news";
import {AxiosResponse} from "axios";

export default class News extends BaseApiClass {

  async getNews(query?:FilterParams): Promise<Paginated<StateNews[]>> {
    const { data } = (await this.axios.get(
      '/api/news/', {params: query}
    )) as AxiosResponse<Paginated<StateNews[]>>
    return data
  }

  async createNews(dataForm) {
    const { data } = (await this.axios.post(
      `/api/news/`, dataForm
    )) as AxiosResponse<StateNews>;
    return data
  }

  async getCategories(query?:FilterParams): Promise<Paginated<NewsCategory[]>> {
    const { data } = (await this.axios.get(
      '/api/news/categories/', {params: query}
    )) as AxiosResponse<Paginated<NewsCategory[]>>
    return data
  }

  async uploadImage(dataForm) {
    const { data } = (await this.axios.post(
      `/api/news/images/`, dataForm
    )) as AxiosResponse<NewsImage>
    return data
  }

  async getCurrentNews(id: number) {
    const { data } = (await this.axios.get(
      `/api/news/${id}/`,
    )) as AxiosResponse<StateNews>
    return data
  }

  async changeCurrentNews(id: number, dataForm) {
    const { data } = (await this.axios.put(
      `/api/news/${id}/`, dataForm
    )) as AxiosResponse<StateNews>
    return data
  }

  async editCurrentNews(id: number, dataForm) {
    const { data } = (await  this.axios.patch(
      `/api/news/${id}/`, dataForm
    )) as AxiosResponse<StateNews>
    return data
  }

  async deleteCurrentNews(id: number) {
    const { data } = (await this.axios.delete(
      `/api/news/${id}/`
    )) as AxiosResponse<null>
    return data
  }

  async like(id: number) {
    const { data } = (await this.axios.patch(
      `/api/news/${id}/like/`
    )) as AxiosResponse<Like>
    return data
  }

  async watch(id: number) {
    const { data } = (await this.axios.patch(
      `/api/news/${id}/watch/`
    )) as AxiosResponse<null>
    return data
  }

  async publishCurrentNews(id: number) {
    const { data } = (await this.axios.patch(
      `/api/news/${id}/publish/`
    )) as AxiosResponse<null>
    return data
  }

  async unpublishCurrentNews(id: number) {
    const { data } = (await this.axios.patch(
      `/api/news/${id}/un-publish/`
    )) as AxiosResponse<null>
    return data
  }
};
