






















import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { SocialAuthType, SocialNetwork } from '~/declarations/shared';


@Component
export default class SocialsAuth extends Vue {
  @Prop({ type: Array, default: () => ([]) })
  socials!: Array<SocialNetwork>

  // eslint-disable-next-line require-await
  async goTo(type: SocialAuthType): Promise<void> {
    // const {url}  = await this.$api.auth.getSocialLink(type);
    // TODO: переписать когда завезем больше провайдеров oauth2
    if (type === 'VK') {
      const url = '/api/social-auth/login/vk-oauth2';
      window.location.href = url;
    } else if (type === 'google') {
      const url = '/api/social-auth/login/google-oauth2';
      window.location.href = url;
    } else if (type === 'odnoklassniki') {
      const url = '/api/social-auth/login/odnoklassniki-oauth2';
      window.location.href = url;
    } else {
      this.$toast.info('Авторизация через данную соцсеть в данный момент не поддерживается.');
    }
  }
}
