import { MutationTree } from 'vuex';

export interface SocialAuthStateInterface {
  socialAuthAccessToken: string;
  phone: string;
  phoneConfirmationRequired: boolean;
};

export const state = (): SocialAuthStateInterface => ({
  socialAuthAccessToken: '',
  phone: '',
  phoneConfirmationRequired: false,
});


export const mutations: MutationTree<SocialAuthStateInterface> = {
  setState(state, response: SocialAuthStateInterface) {
    state.socialAuthAccessToken = response.socialAuthAccessToken;
    state.phone = response.phone || '';
    state.phoneConfirmationRequired = response.phoneConfirmationRequired;
  },

  clearState(stateInstance) {
    stateInstance = state();
  },
};
