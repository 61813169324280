import { Context, Plugin } from '@nuxt/types';
import { Severity } from '@sentry/vue';
import { AxiosError, AxiosRequestConfig } from 'axios';

const config: Plugin = ({ $axios, $sentry, app }: Context) => {
  $axios.onError((error: AxiosError) => {
    if (error.response && error.response.status === 403) {
      if (error.response.data?.detail) {
        error.message = error.response.data?.detail;
      } else if ((error.response.headers['content-type'] || '').includes('text/html')) {
        console.log(error.response);
        error.name = 'Not JSON 403 answer!!!';
        $sentry.captureException(error, (scope) => {
          scope
            .setLevel(Severity.Critical)
            .setExtra('endpoint', error.response?.config.url)
            .setExtra('headers', error.response?.headers)
            .setExtra('responseContent', error.response?.data);
          return scope;
        });
      }
    }
    throw error;
  });

  $axios.onRequest((config: AxiosRequestConfig) => {
    if (app.$typedStore.state.organization.activeOrganization) {
      config.headers.common.OrganizationId = app.$typedStore.state.organization.activeOrganization;
    }
  });
};
export default config;
