<template>
  <ModalBody :title="title" @closeModal="closeModal">
    <div class="modal__content">
      <ValidationObserver v-slot="{ handleSubmit }" name="newPass" slim>
        <form class="form-modal" @submit.prevent="handleSubmit(submitNewPass)">
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="password"
            :rules="{
              required: true,
              password: true,
            }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="password">
              Пароль <span class="input-req"> *</span>
            </label>
            <div class="input-with-icon">
              <input
                id="password"
                v-model="pass"
                type="password"
                class="input"
                :class="{ 'input--error': validated && errors.length > 0 }"
                autocomplete="off"
                @focus="resetShowPassword"
              />
              <button
                class="icon-eye"
                type="button"
                tabindex="-1"
                @click.prevent="toggleShowPassword"
              >
                <svg-icon name="eye" width="22" height="16"></svg-icon>
              </button>
            </div>
            <span v-if="validated && errors.length > 0" class="label__error">{{
              errors[0]
            }}</span>
            <span class="message"
              >Пароль должен содержать и буквы, и цифры, не менее 8
              символов</span
            >
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ validated, errors }"
            name="secPassword"
            :rules="{
              required: true,
              setPassword: ['@password'],
            }"
            tag="div"
            class="input-wrapper"
          >
            <label class="label" for="sec-password">
              Подтверждение пароля <span class="input-req"> *</span>
            </label>
            <div class="input-with-icon">
              <input
                id="sec-password"
                v-model="secPass"
                type="password"
                class="input"
                :class="{ 'input--error': validated && errors.length > 0 }"
                autocomplete="off"
                @focus="resetShowPassword"
              />
              <button
                class="icon-eye"
                type="button"
                tabindex="-1"
                @click.prevent="toggleShowPassword"
              >
                <svg-icon name="eye" width="22" height="16"></svg-icon>
              </button>
            </div>
            <span v-if="validated && errors.length > 0" class="label__error">{{
              errors[0]
            }}</span>
          </ValidationProvider>
          <!-- // buttons  -->
          <button class="btn btn-green btn--arrow form__button" type="submit">
            <span>Продолжить</span>
            <svg-icon
              name="arrow"
              width="6px"
              height="10px"
              stroke="#ffffff"
            ></svg-icon>
          </button>
        </form>
      </ValidationObserver>
    </div>
  </ModalBody>
</template>

<script>
import ModalBody from '@/components/Modals/ModalBody/ModalBody.vue';
import {
  toggleShowPassword,
  resetShowPassword,
} from '@/constants/constFunctions';
import { AUTH_COMPONENTS } from '@/constants/constList';

export default {
  name: 'ModalNewPass',
  components: {
    ModalBody,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: 'Смена пароля',
      pass: '',
      secPass: '',
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async submitNewPass() {
      const data = {
        new_password: this.pass,
      };
      try {
        if (this.$auth.loggedIn) {
          this.$auth.strategy.token.set(this.params.token);
        }
        const result = await this.$axios.put('/api/change-password/', data, {
          headers: {
            Authorization: 'Token ' + this.params.token,
          },
        });
        if (!result.data.error) {
          this.$emit('openModalNext', { modal: AUTH_COMPONENTS.success });
        }
      } catch (e) {
        if (e instanceof Error) {
          this.errorsRequest = [e.message];
        } else {
          console.log(e);
        }
      }
    },
    toggleShowPassword,
    resetShowPassword,
  },
};
</script>
