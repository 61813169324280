import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class HasHeaderData extends Vue {

  get breadCrumbs() {
    const { breadCrumbs } = this.headerData;
    if (Array.isArray(breadCrumbs)) {
      return breadCrumbs;
    }
    if (typeof breadCrumbs === 'function') {
      const generator = breadCrumbs.bind(this);
      return generator();
    }
    return [];
  }

  get headerData() {
    const data = this.$route.matched.map((r) => {
      // @ts-ignore
      return r.components.default.options.headerData;
    })[0];
    return data;
  }

  get titlePage() {
    const title = this.headerData.title;
    if (typeof title === 'function') {
      const titleBind = title.bind(this);
      return titleBind();
    }
    return title;
  }

  get isBackButton() {
    const isBackButton = this.headerData.isBackButton;
    if (typeof isBackButton === 'function') {
      const buttonBind = isBackButton.bind(this);
      return buttonBind();
    }
    return isBackButton;
  }

  get buttonPage() {
    const button = this.headerData.buttonHeader;
    if (typeof button === 'function') {
      const buttonBind = button.bind(this);
      return buttonBind();
    }
    return button;
  }

  get bgGrey() {
    const bg = this.headerData.bgGrey;
    if (typeof bg === 'function') {
      const buttonBind = bg.bind(this);
      const resButton = buttonBind();
      return resButton;
    }
    return bg;
  }
}
