var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBody',{attrs:{"title":_vm.title,"closable":false}},[_c('div',{staticClass:"modal__content"},[(_vm.errorsRequest.length)?_c('div',{staticClass:"errors"},_vm._l((_vm.errorsRequest),function(error){return _c('p',{key:error},[_vm._v(_vm._s(error))])}),0):_vm._e(),_vm._v(" "),_c('ValidationObserver',{attrs:{"name":"phone-confirmation","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-modal",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitPhoneConfirmation)}}},[_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"phone","rules":{ required: true, phone: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validated = ref.validated;
var errors = ref.errors;
return [_c('label',{staticClass:"label",attrs:{"for":"phone-reg"}},[_vm._v("\n            Номер телефона "),_c('span',{staticClass:"input-req"},[_vm._v(" *")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"},{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],staticClass:"input",class:{
              'input--error': validated && errors.length > 0,
            },attrs:{"id":"phone-reg","type":"text","autocomplete":"off","placeholder":"+7 (___) ___-__-__"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_vm._v(" "),(validated && errors.length > 0)?_c('span',{staticClass:"label__error"},[_vm._v("\n            "+_vm._s(errors[0])+"\n          ")]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn btn-green btn--arrow form-modal__button",attrs:{"type":"submit","disabled":_vm.backendLoading}},[_c('span',[_vm._v("Да, это мой номер")])])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }