<template>
  <component :is="tagName" :class="classComponent">
    <div class="container">
      <slot></slot>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    classComponent: {
      type: String,
      default: '',
    },
    tagName: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss">
.container {
  max-width: $maxWidthContainer + px;
  width: 100%;
  margin: 0 auto;
  padding-left: 31px;
  padding-right: 31px;
  @include mediaMax($lg) {
    max-width: none;
  }
  @include mediaMax($sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>