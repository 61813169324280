import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Block, BlockContent } from '~/declarations/content';
import { StateInterface } from '~/store/index';
import Content from '~/api/content';

export interface ContentStateInterface {
  content: Record<Block, BlockContent>;
};

export const state = (): ContentStateInterface => ({
  content: {},
});


export const mutations: MutationTree<ContentStateInterface> = {
  setState(state, { block, content }: { block: Block, content: BlockContent }) {
    state.content[block] = content;
  },

  clearState(stateInstance) {
    stateInstance = state();
  },
};

export const actions: ActionTree<ContentStateInterface, StateInterface> = {
  async getBlockContent(context, block: Block) {
    const Api = new Content(this.$axios);
    const content = await Api.getBlock(block);
    context.commit('setState', {block, content})
  }
};

// export const getters: GetterTree<ContentStateInterface, StateInterface> = {
//   getContent: (context, block: Block): BlockContent => {
//     return context.content[block];
//   }
// };
