/* eslint-disable class-methods-use-this,camelcase */
/* Загрузка файла приходящего по асинхронному запросу */
class DownloaderHelper {
  saveFileFromResponse(response: { headers: { [x: string]: any }; data: BlobPart }, fileName: string) {
    let filename: any = this.parseResponseHeaders(response.headers['content-disposition']);
    // console.log(response.headers['content-disposition']);
    // console.log(filename);
    try {
      filename = filename['filename*'] ? filename['filename*'] : JSON.parse(filename.filename);
    } catch (e) {
      filename = fileName
    }

    const name = decodeURI(filename);
    // vue resource не трогает содержимое текстовых файлов,
    // поэтому делать из него Blob нужно самостоятельно
    if (typeof response.data === 'string') {
      response.data = new Blob(
        [response.data], // первый аргумент - массив
        { type: response.headers['content-type'] }
      );
    }

    this.saveFile(response.data, name);
  }

  parseResponseHeaders(headerStr: string) {
    const headers = {};
    if (!headerStr) {
      return headers;
    }
    const headerPairs = headerStr.split('; ');
    for (let i = 0, len = headerPairs.length; i < len; i += 1) {
      const headerPair = headerPairs[i];
      const index = headerPair.indexOf('=');
      if (index > 0) {
        const key = headerPair.substring(0, index);
        let val = headerPair.substring(index + 1);
        if (key === 'filename*') {
          val = val.substring(7);
        }
        if (key === 'filename') {
          val = val.substring(0, val.length);
        }
        headers[key] = decodeURI(val);
      }
    }
    return headers;
  }

  saveFile(blob: any, name: string | undefined) {
    // @ts-ignore
    name = name || 'download';
    // Use native saveAs in IE10+
    if (typeof navigator !== 'undefined') {
      if (/MSIE [1-9]\./.test(navigator.userAgent)) {
        alert('IE is unsupported before IE10');
        return;
      }
      if ((navigator as any).msSaveOrOpenBlob) {
        // https://msdn.microsoft.com/en-us/library/hh772332(v=vs.85).aspx
        // alert('will download using IE10+ msSaveOrOpenBlob');
        (navigator as any).msSaveOrOpenBlob(blob, name);
        return;
      }
    }
    // Construct URL object from blob
    const win_url = window.URL || window.webkitURL || window;
    const url = win_url.createObjectURL(blob);
    // Use a.download in HTML5
    const a = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    if ('download' in a) {
      // alert('will download using HTML5 a.download');
      // @ts-ignore
      a.href = url;
      // @ts-ignore
      a.download = name;
      // @ts-ignore
      a.dispatchEvent(new MouseEvent('click'));
      // Don't revoke immediately, as it may prevent DL in some browsers
      setTimeout(() => {
        win_url.revokeObjectURL(url);
      }, 500);
      return;
    }
    // Use object URL directly
    window.location.href = url;
    // Don't revoke immediately, as it may prevent DL in some browsers
    setTimeout(() => {
      win_url.revokeObjectURL(url);
    }, 500);
  }
}

const helper = new DownloaderHelper();

export default helper;
