import { createLogger, Store } from 'vuex';

import { CashStateInterface } from './cash';
import { CreatedEventsInterface } from './createdEvents';
import { EnumsInterface } from './enums';
import { EventCreatorInterface } from './eventCreator';
import { EventListInterface } from './eventsList';
import { NotifyStateInterface } from './notify';
import { OrganizationStateInterface } from './organization';
import { ProfileInfoStatisticInterface } from './profileInfoStatistic';
import { UserFavoriteInterface } from './userFavorites';
import { UserSubscribeInterface } from './userSubscribe';
import { SocialAuthStateInterface } from './socialAuth';
import { ContentStateInterface } from '~/store/content';
import { Context } from '@nuxt/types';

export interface StateInterface {
  cash: CashStateInterface;
  createdEvents: CreatedEventsInterface;
  enums: EnumsInterface;
  eventCreator: EventCreatorInterface;
  eventList: EventListInterface;
  notify: NotifyStateInterface;
  organization: OrganizationStateInterface;
  profileInfoStatistic: ProfileInfoStatisticInterface;
  userFavorites: UserFavoriteInterface;
  userSubscribe: UserSubscribeInterface;
  socialAuth: SocialAuthStateInterface;
  content: ContentStateInterface;
}

export const plugins = [
  // createLogger(),
];

export const actions = {
  async nuxtServerInit({ dispatch }: Store<StateInterface>, nuxtContext: Context) {
    await Promise.all([
      dispatch('content/getBlockContent', 'menu'),
      dispatch('content/getBlockContent', 'footer'),
      dispatch('content/getBlockContent', 'lk'),
    ]);
  },
};
