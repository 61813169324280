<template>
  <div class="circles-rate">
    <svg-icon
      v-for="n in ceilRate"
      :key="n"
      name="rate"
      width="16px"
      height="16px"
    />
    <svg-icon v-if="halfType" :name="halfType" width="16px" height="16px" />
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ceilRate() {
      return Math.floor(this.rating);
    },
    halfType() {
      const difference = this.rating - this.ceilRate;
      if (difference === 0.25) {
        return 'rate-quarter';
      } else if (difference === 0.5) {
        return 'rate-half';
      } else if (difference === 0.75) {
        return 'rate-half-quarter';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.circles-rate {
  & svg:not(:last-child) {
    margin-right: 5px;
  }
}
</style>