

























































import { ROUTER_LINK } from '@/constants/constList';

import ContainerSm from '@/components/ui/ContainerSm.vue';
import Navigation from '@/components/Navigation.vue';
// import Socials from '@/components/Socials/Socials.vue';
import SubscribeForm from '@/components/SubscribeForm.vue';
import FooterCopyrigth from '@/components/Footer/FooterCopyrigth.vue';
import { Component, mixins } from 'nuxt-property-decorator';
import HasMenu from '~/mixins/has-menut';

@Component({
  name: 'Footer',
  components: {
    ContainerSm,
    // Socials,
    SubscribeForm,
    FooterCopyrigth,
    Navigation,
  },
})
export default class Footer extends mixins(HasMenu) {

  get footerContent() {
    return this.$typedStore.state.content.content.footer;
  }

  get contacts() {
    return {
      mail: this.footerContent.texts.footer_text1,
      phone: 'dfkjhdsfkjhd',
    };
  }

  get cooperationText(): string {
    return this.footerContent.texts.footer_text2;
  }

  cooperationLink = ROUTER_LINK.about;

  socials = [
    {
      type: 'svg',
      name: 'social-fb',
      nameHv: 'fb-hv',
      url: 'https://facebook.com',
      width: '8.5',
      height: '17.5',
    },
    {
      type: 'svg',
      name: 'social-vk',
      nameHv: 'vk-hv',
      url: 'https://vk.com',
      width: '19',
      height: '11',
    },
    {
      type: 'image',
      name: '/img/icons/inst.svg',
      nameHv: '/img/icons/inst-hv.svg',
      url: 'https://instagram.com',
      width: '15',
      height: '15',
    },
  ];

  get addressCompany(): string {
    return this.footerContent.texts.footer_text3;
  }

  get isMobile() {
    return this.windowWidth < 1025 || this.$device.isMobileOrTablet;
  }
};
