







































































// constant
import { ROUTER_LINK } from '@/constants/constList';
import { addRemoveBodyClass } from '@/constants/constFunctions';

import ContainerXl from '@/components/ui/ContainerXl.vue';
import Navigation from '@/components/Navigation.vue';
import Logo from '@/components/Logo.vue';
import AuthLink from '@/components/Header/AuthLink.vue';
import SearchLink from '@/components/Header/SearchLink.vue';
import ActionsUser from '@/components/Header/ActionsUser.vue';
import ModalSearch from '@/components/Modals/ModalSearch.vue';
import { Component, mixins } from 'nuxt-property-decorator';
import HasMenu from '~/mixins/has-menut';

@Component<Header>({
  name: 'Header',
  components: {
    ContainerXl,
    Navigation,
    Logo,
    AuthLink,
    SearchLink,
    ActionsUser,
    ModalSearch,
  },
  watch: {
    isSearchOpen(value) {
      // const header = this.$refs.header.$el;
      // const body = document.querySelector('body');
      addRemoveBodyClass(value);
    },
    '$auth.loggedIn'(value) {
      console.log('auth', value);
      this.clearInterval();
      if (value) {
        this.$typedStore.dispatch('notify/getNotifications');
        this.intervalNotify = setInterval(
          () => {
            this.$typedStore.dispatch('notify/getNotifications');
          },
          this.timerIntervalNotify,
          true,
        );
      } else {
        this.$typedStore.commit('notify/clearNotifications');
      }
    },
  },
})
export default class Header extends mixins(HasMenu) {

  authUrl = '/autorization';

  searchUrl = '/search';

  chatUrl = ROUTER_LINK.myChat;

  notifyUrl = ROUTER_LINK.myNotifications.main;

  isHeaderMenuOpen = false;

  isSearchOpen = false;

  intervalNotify: null | number = null;

  timerIntervalNotify = 30000;

  get isLogin() {
    return this.$auth.loggedIn;
  }

  get isMobile() {
    return this.windowWidth < 1025 || this.$device.isMobileOrTablet;
  }

  get isOrganization() {
    return this.$typedStore.state.organization.activeOrganization;
  }

  get isNotify() {
    return this.$typedStore.state.notify.isNewNotif;
  }

  mounted() {
    if (this.$auth.loggedIn) {
      this.$typedStore.dispatch('notify/getNotifications');
      this.intervalNotify = setInterval(
        () => {
          this.$typedStore.dispatch('notify/getNotifications');
        },
        this.timerIntervalNotify,
        true,
      );
    }
  }

  destroyed() {
    this.clearInterval();
  }

  private clearInterval() {
    if (this.intervalNotify) {
      clearInterval(this.intervalNotify);
    }
  }

  openModalAuth() {
    this.$emit('openModalAuth');
  }

  // openModalSearch() {
  //   this.$emit('openModalSearch');
  // }
  handlerToggleMobileMenu() {
    if (!this.isHeaderMenuOpen) {
      this.isHeaderMenuOpen = true;

      addRemoveBodyClass(this.isHeaderMenuOpen);
    } else {
      this.isHeaderMenuOpen = false;

      addRemoveBodyClass(this.isHeaderMenuOpen);
    }
  }

  closeMobileMenu() {
    this.isHeaderMenuOpen = false;
    addRemoveBodyClass(this.isHeaderMenuOpen);
  }

  openModalSearch() {
    this.isSearchOpen = true;
  }

  closeSearch() {
    this.isSearchOpen = false;
  }
};
